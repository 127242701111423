/*
  Index.js
  Default js file.
  Displays the app on the index.html page
*/

import React from 'react';
import ReactDOM from 'react-dom';
import Controller from './Controller'

ReactDOM.render(
  <Controller 
  />,
  document.getElementById('root')
);

