let mapData = {
  "Present": '20702',
  "TaskOnePresent": '21151',
  "TaskTwoPresent": '21161',
  "TaskTwoPresentBody": '20932',
  "TaskTwoPresentConclusion": '21140',
  "TaskThreePresent": '21155',
  "TaskFourPresent": '21157',
  "SocialPresent": '21159',
  "SocialImagePresent": '21154',
}

export default mapData;