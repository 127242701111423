/*
	Controller.js
	Manages the game by handling DGDL game names, setting music playback,
	handling bot and user choices.

	Renders both the App itself and the Error boundary
*/

import React, {useState, useEffect} from 'react';
import App from './App';
import ErrorBoundary from './ErrorBoundary';


const Controller = (props) => {

	const [level, setLevel] = useState(0);
	const [botChoice, setBotChoice] = useState("");
	const [userChoice, setUserChoice] = useState("");
	const [game, setGame] = useState("casualfakenewsdialogueintro"); 
	// const [game, setGame] = useState("vaccinegame"); 
	const [playMusic, setPlayMusic] = useState(false);

	const handleBotChoice = (selection) => {		setBotChoice(selection);	}
	const handleUserSelection = (selection) => {	setUserChoice(selection);	}
	const handlePlayMusic = () => {					setPlayMusic(true); 	}

	const handleGameSwitch = (chosen_level) => {

		// Three main levels of the chatbot
		if(chosen_level == 1){			setGame("casualfakenewsdialoguelevelonepone")		}
		else if(chosen_level == 2){		setGame("casualfakenewsdialogueleveltwopone")		}
		else if(chosen_level == 3){		setGame("casualfakenewsdialoguelevelthree")		}

		// Level one subsections
		if(chosen_level == 12){ 		setGame("casualfakenewsdialogueleveloneptwo")		}
		else if(chosen_level == 13){ 	setGame("casualfakenewsdialoguelevelonepthree") 	}
		else if(chosen_level == 14){ 	setGame("casualfakenewsdialoguelevelonepfour") 	}
		else if(chosen_level == 15){ 	setGame("casualfakenewsdialoguelevelonepfive") 	}
		else if(chosen_level == 16){	setGame("casualfakenewsdialoguelevelonepsix")		}

		// Level two subsections
		if(chosen_level == 22){			setGame("casualfakenewsdialogueleveltwoptwo") 	}
		else if(chosen_level == 23) {	setGame("casualfakenewsdialogueleveltwopthree")	}
		else if(chosen_level == 24) {	setGame("casualfakenewsdialogueleveltwopfour")	}
		else if(chosen_level == 25) {	setGame("casualfakenewsdialogueleveltwopfive")	}
		else if(chosen_level == 26) {	setGame("casualfakenewsdialogueleveltwopsix")		}


		// Vaccine game (Journalist)
		
		if(chosen_level == 101) {		setGame("multiplechoice")		}
		if(chosen_level == 90){			setGame("vaccineintro")		}
		if(chosen_level == 91){			setGame("vaccine")			}
		if(chosen_level == 92) {		setGame("vaccinetwo")			}
		if(chosen_level == 921) {		setGame("vaccinetwopone")		}
		if(chosen_level == 922) {		setGame("vaccinetwoptwo")		}
		if(chosen_level == 923) {		setGame("vaccinetwopthree")	}
		if(chosen_level == 93) {		setGame("vaccinethree")		}
		if(chosen_level == 94) {		setGame("vaccinefour")	}


		if(chosen_level == 100) { 		setGame("vaccinegame") 		}
		if(chosen_level == 200) {		setGame("vaccinechoice")	}
		if(chosen_level == 201) {		setGame("vaccine_sources_reaction")	}
		if(chosen_level == 202) {		setGame("vaccine_sources_immunisation")	}
		if(chosen_level == 203) {		setGame("vaccine_sources_manufacturers")	}
		if(chosen_level == 204) {		setGame("vaccine_sources_politicising")	}
		if(chosen_level == 205) {		setGame("vaccine_social_reaction")	}
		if(chosen_level == 206) {		setGame("vaccine_social_immunisation")	}
		if(chosen_level == 207) {		setGame("vaccine_social_manufacturers")	}
		if(chosen_level == 208) {		setGame("vaccine_social_politicising")	}
		if(chosen_level == 209) {		setGame("vaccine_article_reaction")	}
		if(chosen_level == 210) {		setGame("vaccine_article_immunisation")	}
		if(chosen_level == 211) {		setGame("vaccine_article_manufacturers")	}
		if(chosen_level == 212) {		setGame("vaccine_article_politicising")	}
		if(chosen_level == 213) {		setGame("vaccine_headlines_reaction")	}
		if(chosen_level == 214) {		setGame("vaccine_headlines_immunisation")	}
		if(chosen_level == 215) {		setGame("vaccine_headlines_manufacturers")	}
		if(chosen_level == 216) {		setGame("vaccine_headlines_politicising")	}

		// Update game level
		setLevel(chosen_level);
	}

	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const chatbot_type = urlParams.get('chatbot_type');
	useEffect(() => {

        console.log(chatbot_type)
        if(chatbot_type != null) {
            handleBotChoice(1)
            setGame("vaccinegame") 	
        }

        // if(chatbot_type == '"0"'){              game_switch(90);    }
        // else if(chatbot_type == '"1"') {        game_switch(91);    }
        // else if(chatbot_type == '"2"') {        game_switch(92);    }
        // else if(chatbot_type == '"21"') {       game_switch(921);   }
        // else if(chatbot_type == '"22"') {       game_switch(922);   }
        // else if(chatbot_type == '"23"') {       game_switch(923);   }
        // else if(chatbot_type == '"3"'){         game_switch(93);    }
        // else if(chatbot_type == '"4"'){         game_switch(94);    }
        // else if(chatbot_type == '"vaccine"'){   game_switch(100);   }
        // else if(chatbot_type == '"mc"') {       game_switch(101);   }

    }, [chatbot_type])
		

	return (
		<>
			<ErrorBoundary
				key={"ComponentErrorBoundary"}
			>
				<App
					key={"App"}
					game_switch={(chosen_level) => handleGameSwitch(chosen_level)}
					game={game}
					handlePlayMusic={() => handlePlayMusic()}
					botChoice={botChoice}
					userChoice={userChoice}
					handleBotChoice={(bot) => handleBotChoice(bot)}
					handleUserSelection={(bot) => handleUserSelection(bot)}
				/>
			</ErrorBoundary>
		</>
	)
}

export default Controller;
