/*
    MusicFiles.js
    Loads the music files and exports as array to be loaded by Music.js
 */

import music1 from "./sounds/music/1.mp3"
import music2 from "./sounds/music/2.mp3"
import music3 from "./sounds/music/3.mp3"
import music4 from "./sounds/music/4.mp3"
import music5 from "./sounds/music/5.mp3"
import music6 from "./sounds/music/6.mp3"
import music7 from "./sounds/music/7.mp3"
import music8 from "./sounds/music/8.mp3"
import music9 from "./sounds/music/9.mp3"
import music10 from "./sounds/music/10.mp3"
import music11 from "./sounds/music/11.mp3"
import music12 from "./sounds/music/12.mp3"

export default [
    music1,
    music2,
    music3,
    music4,
    music5,
    music6,
    music7,
    music8,
    music9,
    music10,
    music11,
    music12
];