/*
    Music.js
    Handles the music including play/pause and loading the music files
 */

import React, {useEffect, useState} from 'react';
import musicFiles from './MusicFiles'
import img_MusicOn from './images/other/musicON.png'
import img_MusicOff from './images/other/musicOFF.png'

const Music = (props) => {

    const [img_Music, setImgMusic] = useState(img_MusicOff);
    const [audio, setAudio] = useState(musicFiles[0]);
    const [audioCounter, setAudioCounter] = useState(1);

    const style_PlayPauseButton = {
        height: "40%",
        right: "10px",
        position: "absolute"
    }

    const musicChange = () => {
        var ply = document.getElementById('player'); 

        setAudioCounter(audioCounter + 1)
        if(audioCounter == musicFiles.length - 1){
            setAudioCounter(0)
        } 
        
        setAudio(musicFiles[audioCounter])
        ply.load();
        ply.play();
    }

    const toggleMusic = () => {
        var ply = document.getElementById('player'); 

        if (ply.duration > 0 && !ply.paused) {
            ply.pause();
            setImgMusic(img_MusicOff)
        } else {
            ply.play();    
            setImgMusic(img_MusicOn)
        }
    }

    
    return (
        <>
            <img 
                src={img_Music}
                onClick={toggleMusic}
                style={style_PlayPauseButton}
            />
            <audio  id="player" 
                onEnded={() => musicChange()} 
            >
              <source src={audio} id="audioSource" type="audio/mp3" muted="muted"/>
               Your browser does not support the audio tag.
            </audio>
        </>
    )
}

export default Music;