/*
    PastMove.js

    Displays moves after they are no longer the current available moves.
    Handles fallacy popups
*/

import React, {useState} from 'react'
import Popup from './Popup'

// Images
import socrates_circle from './images/speakers/participants/Socrates_circle.png'
import gorgias_circle from './images/speakers/participants/Gorgias_circle.png'
import aristotle_circle from './images/speakers/participants/Aristotle_circle.png'
import bot1 from './images/speakers/Elena.png'
import bot2 from './images/speakers/Mercutio.png'
import bot3 from './images/speakers/Elinor.png'
import bot4 from './images/speakers/Matt.png';
import bot5 from './images/speakers/Robin.png';
import bot6 from './images/speakers/Simeon.png';
import bot7 from './images/speakers/Kay.png';
import bot8 from './images/speakers/Chris.png';
import bot9 from './images/speakers/Mark.png';

// fallacy emojis
import img_ambiguity_vagueness from './images/fallacies/ambiguity_vagueness.png'
import img_cherry_picking from './images/fallacies/cherry_picking.png'
import img_evading from './images/fallacies/evading.png'
import img_false_analogy from './images/fallacies/false_analogy.png'
import img_false_authority from './images/fallacies/false_authority.png'
import img_false_cause from './images/fallacies/false_cause.png'
import img_hasty_generalization from './images/fallacies/hasty_generalization.png'
import img_post_hoc from './images/fallacies/post_hoc.png'
import img_red_herring from './images/fallacies/red_herring.png'
import img_strawman from './images/fallacies/strawman.png'

import firebase from './firebase';
let analytics = firebase.analytics();
let levelChoiceRecorded = false;

// Definitions for the fallacy popup
let popupDefinitions = 
    {
        ' fallacies ': "A fallacy is an argument that seems valid but is not",
        ' post hoc': "It is assumed that because B happens after A, it happens because of A. In other words a causal relation is attributed where, instead, a simple correlation is at stake. \n\nExample: Claiming that 5G is causing covid19.",
        ' cherry picking': "The act of choosing among competing evidence that which supports a given position, ignoring or dismissing findings which do not support it. \n\nEXAMPLE: When a politician announces that schools should be open because one research indicates that kids are less affected by a virus, while other research suggests otherwise.",
        ' false cause': "X is identified as the cause of Y when another factor Z causes both X and Y OR X is considered the cause of Y when actually it is the opposite. \n\nEXAMPLE: When someone claims that ibuprofen makes Covid19 worse.",
        ' strawman ': "An opponent’s argument is misinterpreted in order to attack it more easily. \n\nExample: A tweet stating that Sweden decided to ignore the risks of COVID-19 since schools were not closed.",
        ' hasty generalisation': "A generalization is drawn from a numerically insufficient sample or a sample that is not representative of the population or a sample which is not applicable to the situation if all the variables/circumstances are considered \n\nEXAMPLE: Arguing that all people from a specific race are more likely to refuse to wear face-masks because of one incident.",
        ' evading the burden of proof': "A position is advanced without any reasons supporting it as if it was self-evident. \n\nExample: A politician tweeting that a vaccine for Covid19 was found without providing proof.",
        ' vagueness ': "The meaning of a word or phrase is shifted in the process of an argument or is left vague and therefore open to skewed interpretations. \n\nEXAMPLE: A politician saying that there is a fair number of swabs available without specifying what 'fair' means",
        ' false analogy': "Since X and Y have a quality in common, then whatever applies to X applies also to Y. \n\nExample: When someone compares Covid19 with regular flu.",
        ' false authority': "An appeal to authority is made where the source lacks credibility in the discussed matter or (s)he is attributed a statement which has been tweaked. \n\nExample: When a politician says he knows that the climate crisis does not exist because he did research on it.",
        ' red herring': "The argument is not relevant/partially relevant to assert the conclusion. \n\nExample: When a politician is asked to assess the seriousness of the Covid19 pandemic and replies that corruption is a worse problem."
    }

// Tweaked definitions for the journalist facing chatbot
let vaccine_popupDefinitions = {
    'fallacies' : "A fallacy is an argument that seems valid but is not",
    'Evading the Burden of Proof' : "A position is advanced without any proof supporting it as if it was self-evident. \n\nEXAMPLE: A politician tweeting that a cure for COVID-19 was found without providing proof. \n\nCRITICAL QUESTION: It is not true that there is any evidence apart from the author's personal guarantee.",
    'Strawman' : "An opponent’s argument is misinterpreted in order to attack it more easily. \n\nEXAMPLE: A politician arguing that he does not have to follow the advice of the World Health Organisation (WHO) since it did not give positive results in the past, even though that piece of advice was good at that time and in that context. \n\nCRITICAL QUESTION: It is not true that the other side’s opinion has not been misrepresented (e.g. exaggerated).",
    'Post Hoc' : "Making a causal argument based on two separate circumstances when no or only a vague connection exists between them. \n\nEXAMPLE: Claiming that 5G is causing COVID-19. \n\nCRITICAL QUESTION: It is not true that it is not possible that the situations co-occur by coincidence.",
    'Cherry Picking': "Choosing information that supports a given position, while ignoring or dismissing information which does not support it. \n\nEXAMPLE: When a politician announces that schools should be open because one research project indicates that children are less affected by a virus, whilst different research suggests otherwise. \n\nCRITICAL QUESTION: It is not true that there is not any other data available which would support or refute the claims.",
    'False Cause' : "Claiming a false cause for a particular thing. \n\nEXAMPLE: When someone claims that ibuprofen makes COVID-19 worse, when this is not the case. \n\nCRITICAL QUESTION: It is not true that cause and effect are correctly identified.",
    'Hasty Generalisation' : "Drawing a generalisation from a numerically insufficient sample or a sample that is not representative of the population. \n\nEXAMPLE: Arguing that all people from a specific race are more likely to refuse to wear face-masks because of one incident. \n\nCRITICAL QUESTION: It is not true that the specific case is representative of the broader context.",
    'Hasty Generalisations' : "Drawing a generalisation from a numerically insufficient sample or a sample that is not representative of the population. \n\nEXAMPLE: Arguing that all people from a specific race are more likely to refuse to wear face-masks because of one incident. \n\nCRITICAL QUESTION: It is not true that the specific case is representative of the broader context.",
    'Vagueness' : "The meaning of a word or phrase is shifted in the process of an argument or is left vague and therefore open to skewed interpretations. \n\nEXAMPLE: A council stating that there is a fair number of available swabs without specifying what “fair” means. \n\nCRITICAL QUESTION: It is not true that the word/phrase does not have an ambiguous or vague meaning.",
    'False Analogy' : "Claiming that because two things or situations are alike in one or more respects, they are necessarily alike in some other respect. \n\nEXAMPLE: When someone compares COVID-19 with regular flu. \n\nCRITICAL QUESTION: It is not true that the similarities between two situations are alike for real and relevant to derive the conclusion.",
    'False Authority' : "An appeal to false authority is made where the source lacks credibility in the discussed matter or (s)he is attributed a statement which has been tweaked. \n\nEXAMPLE: When a politician says they know that the climate crisis does not exist because they did research on it. \n\nCRITICAL QUESTION: It is not true that the authority is a genuine and impartial source.",
    'Red Herring' : "When misleading/ distracting/ irrelevant arguments are given for the issue at stake. \n\nEXAMPLE: When a politician is asked to assess the seriousness of the COVID-19 pandemic and replies that corruption is a worse problem. \n\nCRITICAL QUESTION: It is not true that the provided arguments are relevant for the issue at stake."
}

// Corresponding images for the fallacies
let emoji_filenames = {
    ' fallacies ': null,
    ' post hoc': img_post_hoc,
    ' cherry picking': img_cherry_picking,
    ' strawman ': img_strawman,
    ' false cause': img_false_cause,
    ' false authority': img_false_authority,
    ' hasty generalisation': img_hasty_generalization,
    ' evading the burden of proof': img_evading,
    ' vagueness ': img_ambiguity_vagueness,
    ' false analogy': img_false_analogy,
    ' red herring': img_red_herring
}

let emoji_filenames_vaccine = {
    'fallacies': null,
    'Post Hoc': img_post_hoc,
    'Cherry Picking': img_cherry_picking,
    'Strawman': img_strawman,
    'False Cause': img_false_cause,
    'False Authority': img_false_authority,
    'Hasty Generalisation': img_hasty_generalization,
    'Evading the Burden of Proof': img_evading,
    'Vagueness': img_ambiguity_vagueness,
    'False Analogy': img_false_analogy,
    'Red Herring': img_red_herring
}

const PastMove = (props) => {
    let speakerType = props.speakerType
    let moveText = props.moveText
    let move = props.move
    let speaker = props.speaker
    let handlePopupLearnMore = props.handlePopupLearnMore;
    let game_switch = props.game_switch;
    let botChoice = props.botChoice;
    let userChoice = props.userChoice;
    let readMessage = props.readMessage;
    let score = props.score;
    let vaccine_game = props.vaccine_game;
    let addMultipleChoice = props.addMultipleChoice;


    const [showPopup, setShowPopup] = useState(false)
    const [showPopup2, setShowPopup2] = useState(false)

    const styleReadMessage = { opacity: "0.6" }

    const handleButtonClick = (move, speaker) => {
        // Currently the previous moves cannot be clicked.
    }

    // const multipleChoice_sendChoice = (choice) => {
    //     sendChoice(choice);
    // }


    if(props.botChoice == "1"){botChoice = bot1}
    if(props.botChoice == "2"){botChoice = bot2}
    if(props.botChoice == "3"){botChoice = bot3}
    if(props.botChoice == "4"){botChoice = bot4}
    if(props.botChoice == "5"){botChoice = bot5}
    if(props.botChoice == "6"){botChoice = bot6}
    if(props.botChoice == "7"){botChoice = bot7}
    if(props.botChoice == "8"){botChoice = bot8}
    if(props.botChoice == "9"){botChoice = bot9}


    if(moveText.includes("italic(")){
        var regex = /italic\((.*?)\)/g
        moveText = moveText.replace(regex, `<i>$1</i>` );
    }
    

    /*
        Not currently implemented
        The goal is to remove certain strings such as 'it is not the case that'
        are removed from the proposition before being shown in the chatbot.
        These give an indiciation of the truth value of the proposition so are
        undesirable to show when asking the user for their assessment.
     */
    // if(vaccine_game) {
    //     if(moveText.includes("It is not the case that ")) {
    //         moveText = moveText.replace("It is not the case that ", "");
    //         moveText[0] = moveText[0].toUpperCase();
    //     }
    //
    //     if(moveText.includes("is a good point to use")) {
    //         moveText = moveText.replace("is a good point to use", "");
    //     }
    // }


    // Rules only to be applied when running the journalist chatbot version
    if(vaccine_game) {
        let popupString = null;
        let popupString2 = null;
        let popupStringCount = 0;

        for(const key in vaccine_popupDefinitions){
            if(popupStringCount > 0) {
                if(moveText.includes(key.toString())) {
                    popupStringCount += 1;
                    popupString2 = key.toString()
                }
            } else {
                if(moveText.includes(key.toString())) {
                    popupStringCount += 1;
                    popupString = key.toString()
                }
            }
        }

        if(popupString != null) {
            console.log("Popup string 1: " + popupString)
            console.log("Popup string 2: " + popupString2)
        }


        if(popupString != null) {

            if(popupString2 != null) {
                let splits = moveText.split(popupString2)
                let splits2 = splits[1].split(popupString)

                console.log("splits: " + splits)
                console.log("splits2: " + splits2)

                return (
                    <div className="messageLine">
                        {
                            speakerType === "Agent" ?
                                <img
                                    src={botChoice}
                                    className="speakerImage"
                                    style={readMessage ? styleReadMessage : null}

                                />
                                :
                                null
                        }
                        {
                            speakerType === "Socrates" ?
                                <img
                                    className="speakerImage"
                                    src={socrates_circle}
                                    alt="Socrates profile"
                                    style={readMessage ? styleReadMessage : null}
                                />
                                :
                                null
                        }
                        {
                            speakerType === "Aristotle" ?
                                <img
                                    className="speakerImage"
                                    src={aristotle_circle}
                                    style={readMessage ? styleReadMessage : null}
                                />
                                :
                                null
                        }
                        {
                            speakerType === "Gorgias" ?
                                <img
                                    className="speakerImage"
                                    src={gorgias_circle}
                                    style={readMessage ? styleReadMessage : null}
                                />
                                :
                                null
                        }
    
                        <div
                            className={speakerType + "_previous"}
                        >
                            <span
                                className="span_agent_previous"
                                style={readMessage ? styleReadMessage : null}
                            > {splits[0]}</span>

                            <img
                                src={emoji_filenames_vaccine[popupString]}
                                className="image_fallacy_emoji"
                                onClick={() => {setShowPopup(!showPopup)}}
                            />
    
                            <span
                                className="emoji_text"
                                onClick={() => {setShowPopup(!showPopup)}}
                            >{popupString2.trim()}</span>
    
                            {showPopup ?
                                <Popup
                                    learnMore={false}
                                    word={popupString2}
                                    definition={vaccine_popupDefinitions[popupString2]}
                                    dismiss={() => {
                                        setShowPopup(false)
                                        analytics.logEvent('fallacy_dismiss_' + popupString2.replace(/ /g,''), {
                                            test_value: 'test',
                                        });
                                    }}
                                    learnMorePopup={(word) => {handlePopupLearnMore(word)}}
                                />
                            : null}
                            <span
                                className="span_agent_previous"
                                style={readMessage ? styleReadMessage : null}
                            > {splits2[0]}</span>



                            {/* 2 */}

                            <span
                                className="emoji_text"
                                onClick={() => {setShowPopup2(!showPopup2)}}
                            >{popupString.trim()}</span>

                            <img
                                src={emoji_filenames_vaccine[popupString]}
                                className="image_fallacy_emoji"
                                onClick={() => {setShowPopup(!showPopup)}}
                            />

                            {showPopup2 ?
                                <Popup
                                    learnMore={false}
                                    word={popupString}
                                    definition={vaccine_popupDefinitions[popupString]}
                                    dismiss={() => {
                                        setShowPopup2(false)
                                        analytics.logEvent('fallacy_dismiss_' + popupString.replace(/ /g,''), {
                                            test_value: 'test',
                                        });
                                    }}
                                    learnMorePopup={(word) => {handlePopupLearnMore(word)}}
                                />
                            : null}
                            <span
                                className="span_agent_previous"
                                style={readMessage ? styleReadMessage : null}
                            > {splits2[1]}</span>

                        </div>
                    </div>
                )
            } else {
                let splits = moveText.split(popupString)

                return (
                    <div className="messageLine">
                        {
                            speakerType === "Agent" ?
                                <img
                                    src={botChoice}
                                    className="speakerImage"
                                    style={readMessage ? styleReadMessage : null}

                                />
                                :
                                null
                        }
                        {
                            speakerType === "Socrates" ?
                                <img
                                    className="speakerImage"
                                    src={socrates_circle}
                                    alt="Socrates profile"
                                    style={readMessage ? styleReadMessage : null}
                                />
                                :
                                null
                        }
                        {
                            speakerType === "Aristotle" ?
                                <img
                                    className="speakerImage"
                                    src={aristotle_circle}
                                    style={readMessage ? styleReadMessage : null}
                                />
                                :
                                null
                        }
                        {
                            speakerType === "Gorgias" ?
                                <img
                                    className="speakerImage"
                                    src={gorgias_circle}
                                    style={readMessage ? styleReadMessage : null}
                                />
                                :
                                null
                        }
    
                        <div
                            className={speakerType + "_previous"}
                        >
                            <span
                                className="span_agent_previous"
                                style={readMessage ? styleReadMessage : null}
                            > {splits[0]}</span>

                            <img
                                src={emoji_filenames_vaccine[popupString]}
                                className="image_fallacy_emoji"
                                onClick={() => {setShowPopup(!showPopup)}}
                            />
    
                            <span
                                className="emoji_text"
                                onClick={() => {setShowPopup(!showPopup)}}
                            >{popupString.trim()}</span>
    
                            {showPopup ?
                                <Popup
                                    learnMore={false}
                                    word={popupString}
                                    definition={vaccine_popupDefinitions[popupString]}
                                    dismiss={() => {
                                        setShowPopup(false)
                                        analytics.logEvent('fallacy_dismiss_' + popupString.replace(/ /g,''), {
                                            test_value: 'test',
                                        });
                                    }}
                                    learnMorePopup={(word) => {handlePopupLearnMore(word)}}
                                />
                            : null}
                            <span
                                className="span_agent_previous"
                                style={readMessage ? styleReadMessage : null}
                            > {splits[1]}</span>
                        </div>
                    </div>
                )
            }

           
        }
    }


    if(move.moveID == "EndGameScore"){
        if(score == 0){
            moveText = "Alright, great to have e-met you and let’s chat again soon!" 
        } else if(score > 0 && score < 4){
            moveText = "You are getting critical …great job! Don’t be a stoic: don’t suspend your judgement and play more!"
        } else if(score > 3 && score < 7){
            moveText = "Great job, no doubt you are in the making of becoming a real skeptic."
        } else if(score > 7){
            moveText = "You are a proper agnostic, but something is known: you are a pro critical thinker!>"
        } 

    }

    if(move.moveID == "FeedbackURL"){
        return (
            <div
                    className={speakerType + "_previous"}
                    style={readMessage ? styleReadMessage : null}
                >
                    <a 
                        href={"https://liverpoolcommsmedia.fra1.qualtrics.com/jfe/form/SV_cumhqbTOi8rJTud"}
                        target="_blank"
                        className="emoji_text"
                    >Please give us feeback</a>
            </div>
        )
    }

    // Don't show moves with empty openers
    if(moveText == "") {
        return ( <></> )
    }

    // Record level selected by user in firebase
    if(move.moveID == "LevelSelectionOne" && !levelChoiceRecorded) {
        analytics.logEvent('levelChoice_one', { test_value: 'test' });
        levelChoiceRecorded = true;
    } else if(move.moveID == "LevelSelectionTwo" && !levelChoiceRecorded) {
        analytics.logEvent('levelChoice_two', { test_value: 'test' });
        levelChoiceRecorded = true;
    } else if(move.moveID == "LevelSelectionThree" && !levelChoiceRecorded) {
        analytics.logEvent('levelChoice_three', { test_value: 'test' });
        levelChoiceRecorded = true;
    }

    // Deal with links in the journalist facing chatbot
    // TODO: deal with multiple fallacies in the move text
    if((moveText.includes('https:') || moveText.includes('http:')) && (vaccine_game)) {

        var str = "";
        let newsLink = ""
        let text = ""

        if(moveText.includes('https:')) {
            str = moveText;
            newsLink = str.match(`https:.[^\\s]+`);
            text = moveText.split(newsLink[0]);
        } else if(moveText.includes('http:')) {
            str = moveText;
            newsLink = str.match(`http:.[^\\s]+`);
            text = moveText.split(newsLink[0]);
        }


        return (
            <>
                {
                    speakerType === "Agent" ?
                        <img
                            src={botChoice}
                            className="speakerImage"
                            style={readMessage ? styleReadMessage : null}

                        />
                        :
                        null
                }
                {
                    speakerType === "Socrates" ?
                        <img
                            className="speakerImage"
                            src={socrates_circle}
                            alt="Socrates profile"
                            style={readMessage ? styleReadMessage : null}
                        />
                        :
                        null
                }
                {
                    speakerType === "Aristotle" ?
                        <img
                            className="speakerImage"
                            src={aristotle_circle}
                            style={readMessage ? styleReadMessage : null}
                        />
                        :
                        null
                }
                {
                    speakerType === "Gorgias" ?
                        <img
                            className="speakerImage"
                            src={gorgias_circle}
                            style={readMessage ? styleReadMessage : null}
                        />
                        :
                        null
                }
                <div
                    className={speakerType + "_previous"}
                    style={readMessage ? styleReadMessage : null}
                >
                    {text[0]}
                    <a
                        href={newsLink[0]}
                        target="_blank"
                        className="newsLink"
                    >
                        {newsLink[0]}
                    </a>
                    {text[1]}
                </div>
            </>
        )
    }

    // Correctly display hyperlinks
    if(move.moveID.includes("Link")) {
        var str = moveText;
        let newsLink = str.match("<(.*)>");

        if(newsLink[1] == 'https://www.snopes.com/fact-check/china-cellphones-coronavirus'){
            let text1 = moveText.split(newsLink[0])
            let text = moveText.replace(newsLink[0], '');
            return (
                <div
                    className={speakerType + "_previous"}
                    style={readMessage ? styleReadMessage : null}
                >
                    {text1[0]}
                    <a href={newsLink[1]} target="_blank" className="newsLink">Snopes</a>
                    {text1[1]}
                </div>
            )  
        } else {
            let text = moveText.replace(newsLink[0], '');
            return (
                <div
                    className={speakerType + "_previous"}
                    style={readMessage ? styleReadMessage : null}
                >
                    {text}
                    <a
                        href={newsLink[1]}
                        target="_blank"
                        className="newsLink"
                    >
                        {newsLink[1]}
                    </a>
                </div>
            )  
        }
    }

    // Deals with fallacy 'clickables' in the moves.
    // The fallacy text is replaced with the emoji and can be clicked to activate the popup
    if(moveText.includes("#")) {

        let splitText = moveText.split(" ");
        let len = splitText.length;
        let result = [];

        for(let i = 0; i < len; i++) {
            result[i] = splitText[i];
        }
        
        let withinPopupBounds = false;
        let popupString = "";
        
        
        return (
            <div className="messageLine">
                {
                    speakerType === "Agent" ?
                        <img
                            src={botChoice}
                            className="speakerImage"
                            style={readMessage ? styleReadMessage : null}
                        />
                    :
                        null
                }
                {
                    speakerType === "Socrates" ?
                        <img
                        className="speakerImage"
                        src={socrates_circle}
                        />
                    :
                        null
                }
                {
                    speakerType === "Aristotle" ?
                        <img
                        className="speakerImage"
                        src={aristotle_circle}
                        />
                    :
                        null
                }
                {
                    speakerType === "Gorgias" ?
                        <img
                        className="speakerImage"
                        src={gorgias_circle}
                        />
                    :
                        null
                }
                <div className={speakerType + "_previous"} >
                    {result.map(str => {
                        if(str.includes("#") || withinPopupBounds) {
                            if(str.includes("#") && withinPopupBounds) {

                                withinPopupBounds = false;
                                popupString += str.replace("#", "");
                                
                                return (
                                    <>
                                        {
                                            showPopup ?
                                                <Popup
                                                    learnMore={true}
                                                    word={popupString}
                                                    definition={popupDefinitions[popupString]}
                                                    dismiss={() => {
                                                        setShowPopup(false)
                                                        analytics.logEvent('fallacy_dismiss_' + popupString.replace(/ /g,''), {
                                                            test_value: 'test',
                                                        });
                                                    }}
                                                    learnMorePopup={(word) => {handlePopupLearnMore(word)}}
                                                />
                                            :
                                                null
                                        }
                                            
                                        <img 
                                            src={emoji_filenames[popupString]} 
                                            className="image_fallacy_emoji"
                                            onClick={() => {setShowPopup(!showPopup)}}
                                        />

                                        {
                                            popupString === ' fallacies ' ?
                                                <span
                                                    className="emoji_text"
                                                    onClick={() => {setShowPopup(!showPopup)}}
                                                >{popupString.trim()}</span>
                                            :
                                                <span
                                                    className="emoji_text"
                                                    onClick={() => {setShowPopup(!showPopup)}}
                                                >{popupString}</span>
                                        }
                                    </>
                                )
                            } else {
                                popupString += str.replace("#", " ") + ' ';
                                withinPopupBounds = true;
                            }
                        } else {
                            return (
                                <span
                                    className="span_agent_previous"    
                                    style={readMessage ? styleReadMessage : null}
                                > {str}</span>
                            )
                        }
                    })}
                </div>
                <br/>
            </div>
        )
    } else if(move.moveID === "ShowChoices") { // Special output for multiple choice questions
        return (
            <div className="messageLine">
                <div className={"multipleChoice_option"}>
                    <label htmlFor={"multipleChoice_checkBox" + moveText}>{moveText}</label>
                    <input
                        type="checkBox"
                        id={"multipleChoice_checkBox" + moveText}
                        onClick={() => addMultipleChoice(moveText)}
                    />
                </div>
            </div>
        )
    } else { // For text that is not a 'clickable' fallacy, check which user made the move, display their picture, and display the move text
        return (
            <div className="messageLine" >
                {
                    speakerType === "Agent" ?
                        <img
                            src={botChoice}
                            className="speakerImage"
                            style={readMessage ? styleReadMessage : null}

                        />
                    :
                        null
                }
                {
                    speakerType === "Socrates" ?
                        <img
                            className="speakerImage"
                            src={socrates_circle}
                            alt="Socrates profile"
                            style={readMessage ? styleReadMessage : null}
                        />
                    :
                        null
                }
                {
                    speakerType === "Aristotle" ?
                        <img
                            className="speakerImage"
                            src={aristotle_circle}
                            style={readMessage ? styleReadMessage : null}
                        />
                    :
                        null
                }
                {
                    speakerType === "Gorgias" ?
                        <img
                            className="speakerImage"
                            src={gorgias_circle}
                            style={readMessage ? styleReadMessage : null}
                        />
                    :
                        null
                }
                
                {
                    speakerType === "User" ?
                        <>
                            <div
                            className={speakerType + "_previous"}
                            style={readMessage ? styleReadMessage : null}
                            >
                                {moveText}
                            </div>
                        </>
                    :
                        <div
                            className={speakerType + "_previous"}
                            style={readMessage ? styleReadMessage : null}
                            onClick={() => handleButtonClick(move, speaker)}
                            dangerouslySetInnerHTML={{__html: moveText}}
                        >
                        </div>
                }
 
                <br/>
            </div>
        )
    }
}

export default PastMove;