/*
    App.js
    Main file for the chatbot application. 
    Controls the creation of a dialogue, keeping track of available and performed moves, and various game state variables
*/

// Imports
import React, {useRef, useState, useEffect} from 'react';
import mapData from './mapdata/mapdata.js'
import pad from './services/pad'
import Score from './Score'
import JarPage from './JarPage'
import Music from './Music'
import Move from './Move'
import PastMove from './PastMove'
import AgentSidebar from './AgentSidebar'
import Landing from './Landing'
import Philosophers from './Philosophers'
import BotChoice from './BotChoice';
import firebase from './firebase';
import Popup from "./Popup";
let analytics = firebase.analytics();

// Global variables
let dialogueID = null; // Dialogue ID for DGEP API call
let dgep = null; // Returned from pad.js. For DGEP api calls
let interactions = Object(); // Object for storing possible interactions
let showTypingAnimation = true;
let gameEnd = false;
let game_score = 0;
let moveTime = 0.0;
let appearTime = 0.0;
let TotalUserPhilosopherClicks = 0;
let totalUserFallacies = 0;
let multipleChoice_selection = []

// Styling
const header = {
    width: "100%",
    boxShadow: "-1px 14px 12px -2px rgba(119,119,119,0.46)",
    position: "fixed",
    backgroundColor: "#d5e5f6",
    zIndex: "1",
    height: "13%",
    display: "flex",
    alignItems: "center"
}
const headerImage = {
    maxWidth: "25%",
    minWidth: "250px",
    marginLeft: "10px",
    marginTop: "10px",
    marginBottom: "10px"
}

const App = (props) => {
    const axios = require('axios').default;

    let game = props.game;
    let game_switch = props.game_switch;
    let botChoice = props.botChoice;
    let userChoice = props.userChoice;

    let activeAgent = {
        "Aristotle": false,
        "Gorgias":false,
        "Socrates": false,
        "Aristotle_move": "",
        "Gorgias_move": "",
        "Socrates_move": ""
    }

    let participants = [
        {name: "Merle(Agent)", player: "Agent"},
        {name: "Soraya(User)", player: "User"},
        {name: "Hal(Socrates)", player:"Socrates"},
        {name: "Bob(Gorgias)", player:"Gorgias"},
        {name:"John(Aristotle)", player:"Aristotle"}
    ]

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const skipGame = urlParams.get('skip');
    const chatbot_type = urlParams.get('chatbot_type');
    // const mapNumber = urlParams.get('mapnum');

    const [pageState, setPageState] = useState(0);
    const [map_data, setMapData] = useState(mapData);
    const [gameTimer, setGameTimer] = useState(0.0); //The overall time
    const [lastMove, setLastMove] = useState();
    const [pastMoves, setPastMoves] = useState([])
    const [moves, setMoves] = useState([])
    const [counter, setCounter] = React.useState(10);
    const divRef = useRef(null);

    useEffect(() => {
        if(skipGame == 'true') {
            props.handleBotChoice(1)
            setPageState(6);
        }
    }, [skipGame])

    // For testing subsections of the vaccine (journalist) game based on the URL param
    // useEffect(() => {

    //     console.log(chatbot_type)
    //     if(chatbot_type != null) {
    //         props.handleBotChoice(1)
    //         setPageState(0); //TODO: change to 0
    //         game_switch(100);
    //     }

    //     if(chatbot_type == '"0"'){              game_switch(90);    }
    //     else if(chatbot_type == '"1"') {        game_switch(91);    }
    //     else if(chatbot_type == '"2"') {        game_switch(92);    }
    //     else if(chatbot_type == '"21"') {       game_switch(921);   }
    //     else if(chatbot_type == '"22"') {       game_switch(922);   }
    //     else if(chatbot_type == '"23"') {       game_switch(923);   }
    //     else if(chatbot_type == '"3"'){         game_switch(93);    }
    //     else if(chatbot_type == '"4"'){         game_switch(94);    }
    //     else if(chatbot_type == '"vaccine"'){   game_switch(100);   }
    //     else if(chatbot_type == '"mc"') {       game_switch(101);   }

    // }, [chatbot_type])

    // useEffect(() => {
    //     let map_number = { 'number': mapNumber }

    //     if(mapNumber != null) {
    //         let data = "";
    //         let argql_map_promise = new Promise(function(resolve, reject) {
    //             // switch_url
    //             // let url = "http://localhost:3000/middleware/v1/dialogue/map";
    //             let url = "http://middleware.arg.tech/middleware/v1/dialogue/map";

    //             try {
    //                 axios.post(url, map_number).then((response) => {
    //                     data = response.data;
    //                 }).then(() => {
    //                     resolve(data);
    //                 });
    //             } catch(error) {
    //                 return "error";
    //             }
    //         });
    //         argql_map_promise.then(function(data) { });
    //     }
    // }, [mapNumber])


    // useEffect(() => {
    //     // TODO: uncomment
    //     let data = "";
    //     let argql_map_promise = new Promise(function(resolve, reject) {
    //         // switch_url
    //         // let url = "http://localhost:3000/middleware/v1/dialogue/set_map";
    //         let url = "http://middleware.arg.tech/middleware/v1/dialogue/set_map";

    //         try {
    //             axios.post(url, map_data).then((response) => {
    //                 // console.log(response.data);
    //                 data = response.data;
    //             }).then(() => {
    //                 resolve(data);
    //             });

    //         } catch(error) {
    //             return "error";
    //         }
    //     });
    //     argql_map_promise.then(function(data) {});
    // }, [map_data])

    useEffect(() => {
        setTimeout(() => {
            setGameTimer(gameTimer + 100)
            // console.log("Time ", gameTimer);
        }, 100)
    }, [gameTimer])

    useEffect(() => {
        // switch_url
        let p = pad("ws-test.arg.tech");
        // let p = pad("ws-fni.arg.tech");
        // let p = pad("http://localhost:8888")

        dgep = p.load_module("dgep");

        p.ready(() => {
            dgep.auth_login({username: "m",password: "p"}, (data) => {
                p.set_auth_token(data);

                // dgep.dialogue_new(game, {participants: participants, contentSource: 'http://host.docker.internal:5000' }, (data) => {
                dgep.dialogue_new(game, {participants: participants}, (data) => {
                    dialogueID = data['dialogueID'];
                    dgep.dialogue_moves(dialogueID, Process_moves);
                })
            })
        })
    }, [game])

    const setMoveTimes = (moveText, skip= false) => {
        if(!skip) {
            appearTime = gameTimer;
            let words = moveText.split(' ').length;
            let wordTime = words * 250;
            moveTime = wordTime;
        } else {
            moveTime = 0;
        }


        // TODO: Change
        // moveTime = 0;
    }

    const Process_moves = (data) => {
        console.log(data)

        let data_to_send = {}
        data_to_send['move'] = data;
        data_to_send['ID'] = dialogueID
        // data['ID'] = dialogueID;
        // if((game.includes("vaccine")) || (game.includes("multiplechoice"))) {
        //     let argql_promise = new Promise(function(resolve, reject) {
        //         // switch_url
        //         // let url = "http://localhost:3000/middleware/v1/dialogue/argql/moves";
        //         let url = "http://middleware.arg.tech/middleware/v1/dialogue/argql/moves";

        //         console.log(data);
        //         try {
        //             axios.post(url, data_to_send).then((response) => {
        //                 // console.log(response.data);
        //                 data = response.data;
        //             }).then(() => {
        //                 resolve(data);
        //             });
        
        //         } catch(error) {
        //             return "error";
        //         }
        //     });
    
        //     argql_promise.then(function(data) {
        //         interactions = Object();

        //         for(let[player, moves] of Object.entries(data)) {

        //             interactions[player] = Array();
        
        //             for(let move of moves){
        //                 let moveID = move["moveID"];
        //                 let opener = move["opener"];
        //                 let reply = move["reply"];
        //                 let target = move["target"];
        
        //                 interactions[player].push({
        //                     moveID: moveID,
        //                     opener: opener,
        //                     target: target,
        //                     reply: reply,
        //                     speaker: player,
        //                     dialogueID: dialogueID
        //                 });
        
        //                 moveID = move["moveID"];
        //             }
        //         }

        //         if(interactions["Merle(Agent)"] != undefined) {

        //             if(interactions["Merle(Agent)"].length == 0){
        //                 // Attempting to avoid bug in which empty move appears
        //                 // Cause not yet known
        //             } else {
        //                 let timeSinceLastMove = appearTime - gameTimer;
        //                 let timeLeft = moveTime - timeSinceLastMove;
        //                 let newMoves = interactions
        //                 setTimeout(function () { setMoves(newMoves) }, timeLeft)
        //             }
        //         } else {
        //             let timeSinceLastMove = appearTime - gameTimer;
        //             let timeLeft = moveTime - timeSinceLastMove;
        //             let newMoves = interactions
        //             setTimeout(function () { setMoves(newMoves) }, timeLeft)
        //         }
        //     })
            
        // } 
        // else {
            interactions = Object();

            for(let[player, moves] of Object.entries(data)) {
                interactions[player] = Array();
    
                for(let move of moves){
                    let moveID = move["moveID"];
                    let opener = move["opener"];
                    let reply = move["reply"];
                    let target = move["target"];
    
                    interactions[player].push({
                        moveID: moveID,
                        opener: opener,
                        target: target,
                        reply: reply,
                        speaker: player,
                        dialogueID: dialogueID
                    });
    
                    moveID = move["moveID"];
                }
            }

            if(interactions["Merle(Agent)"] != undefined) {
                if(interactions["Merle(Agent)"].length == 0){
                    // Attempting to avoid bug in which empty move appears
                    // Cause not yet known
                } else {
                    let timeSinceLastMove = appearTime - gameTimer;
                    let timeLeft = moveTime - timeSinceLastMove;
                    let newMoves = interactions
                    setTimeout(function () { setMoves(newMoves) }, timeLeft)
                }
            } else {
                let timeSinceLastMove = appearTime - gameTimer;
                let timeLeft = moveTime - timeSinceLastMove;
                let newMoves = interactions
                setTimeout(function () { setMoves(newMoves) }, timeLeft)
            }
        //}
    }

    // Brings 'divRef' into view when a new move is added
    // Has the effect of keeping newer moves in focus when added
    useEffect(() => {
        if(pageState == 6){ divRef.current.scrollIntoView({behavior: 'smooth'});}
    }, [pastMoves, moves]);

    useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    }, [counter]);

    const handleMoveClick = (move, speaker) => {

        if(move.moveID.toString() == "NotEnjoyingResponse") {
            showTypingAnimation = false;
            gameEnd = true;
        } else if(move.moveID == "GameEnd") {
            showTypingAnimation = false;
            gameEnd = true;
        } else if(move.moveID == "EndGameFinal" || move.moveID == "EndGameNo") {
            showTypingAnimation = false;
            gameEnd = true;
        } else {
            showTypingAnimation = true;
        }

        //TODO: why is this 6?
        setCounter(6);
        setMoves([]);
        dgep.dialogue_interaction(dialogueID, move.moveID, move, Process_moves);
        setPastMoves([...pastMoves, {dialogueID: dialogueID, moveID: move.moveID, opener: move.opener, reply: {p: "$p"}, speaker: move.speaker, target: move.target}])
    }

    const handleBotSelection = (bot) => {
        props.handleBotChoice(bot);
        setPageState(pageState + 1);
    }


    const addMultipleChoice = (choice) => {
        console.log("Send choice")

        // multipleChoice_selection.push(choice);
        choice = choice.replace(/['"]+/g, '')
        choice = choice.trim();
        if(!multipleChoice_selection.includes(choice)){          //checking if array contains the id
            multipleChoice_selection.push(choice);               //adding to array because value doesnt exists
        }else{
            multipleChoice_selection.splice(multipleChoice_selection.indexOf(choice), 1);  //deleting
        }

        console.log("MC")
        console.log(multipleChoice_selection)
    }

    // const sendChoice = () => {
    //     let data = ""

    //     let data_to_send = {}
    //     data_to_send['selection'] = multipleChoice_selection;
    //     data_to_send['ID'] = dialogueID

    //     let argql_promise = new Promise(function(resolve, reject) {
    //         // switch_url
    //         // let url = "http://localhost:3000/middleware/v1/dialogue/multiple_choice_selection";
    //         let url = "http://middleware.arg.tech/middleware/v1/dialogue/argql/multiple_choice_selection";

    //         // console.log(data);
    //         try {
    //             axios.post(url, data_to_send).then((response) => {
    //                 // console.log(response.data);
    //                 data = response.data;
    //             }).then(() => {
    //                 resolve(data);
    //             });

    //         } catch(error) {
    //             return "error";
    //         }
    //     });

    //     argql_promise.then(function(data) {
    //         console.log("sent")
    //         multipleChoice_selection = []
    //     })
    // }

    const handleAgentClick = (Agent_move) => {
        showTypingAnimation = true
        TotalUserPhilosopherClicks += 1;
        let speaker = ""

        if(Agent_move.includes("Aristotle")) {
            analytics.logEvent('philosopher_click_aristotle', {
                test_value: 'test',
            });
            speaker = "John(Aristotle)"

        } else if(Agent_move.includes("Gorgias")) {
            analytics.logEvent('philosopher_click_Gorgias', {
                test_value: 'test',
            });
            speaker = "Bob(Gorgias)"
            
        } else if(Agent_move.includes("Socrates")) {
            analytics.logEvent('philosopher_click_Socrates', {
                test_value: 'test',
            });
            speaker = "Hal(Socrates)"
        }

        let move = {
            dialogueID: dialogueID,
            moveID: Agent_move,
            opener: "\"\"",
            reply: {p: "$p"},
            speaker: speaker,
            target: "Soraya(User)"
        }
        setMoves([]);
        dgep.dialogue_interaction(dialogueID, move.moveID, move, Process_moves);
        setPastMoves([...pastMoves, {dialogueID: dialogueID, moveID: move.moveID, opener: move.opener, reply: {p: "$p"}, speaker: move.speaker, target: move.target}])
    }

    // Handle cases of 'learn more'
    // Selects the corresponding move in DGDL for each fallacy
    const handlePopupLearnMore = (word) => {
        setMoves([])
        setLastMove(pastMoves[pastMoves.length - 1]);
        totalUserFallacies += 1;

        let move = {
            dialogueID: dialogueID,
            moveID: "",
            opener: "",
            reply: {p: "$p"},
            speaker: "Merle(Agent)",
            target: "Soraya(User)"
        }

        if(word.trim() === "post hoc") {
			analytics.logEvent('post_hoc_popup', { test_value: 'test' });
			move['moveID'] = "PostHocFirst";
			move['opener'] = "\"Do you wanna know who talked about the post hoc fallacy first?\"";

        } else if (word.trim() === "fallacies") {
			analytics.logEvent('fallacies_popup', { test_value: 'test' });
            move['moveID'] = "FallaciesFirst"
            move['opener'] = "\"Do you wanna know who talked about fallacies first?\""

        } else if (word.trim() === "strawman") {
			analytics.logEvent('strawman_popup', { test_value: 'test' });
            move['moveID'] = "StrawmanFirst"
            move['opener'] = "\"Do you wanna know who talked about the strawman fallacy first?\""

        } else if (word.trim() === "cherry picking") {
			analytics.logEvent('cherry_picking_popup', { test_value: 'test' });
            move['moveID'] = "CherryPickingFirst"
            move['opener'] = "\"Do you wanna know who talked about the cherry picking fallacy first?\""

        } else if (word.trim() === "false cause") {
			analytics.logEvent('false_cause_popup', { test_value: 'test' });
            move['moveID'] = "FalseCauseFirst"
            move['opener'] = "\"Do you wanna know who talked about the false cause fallacy first?\""

        } else if (word.trim() === "hasty generalisation") {
            analytics.logEvent('hasty_generalisation_popup', { test_value: 'test' });
            move['moveID'] = "HastyGeneralisationFirst"
            move['opener'] = "\"Do you wanna know who talked about the Hasty Generalization fallacy first?\""

        } else if (word.trim() === "evading the burden of proof") {
            analytics.logEvent('evading_proof_popup', { test_value: 'test' });
            move['moveID'] = "EvadingFirst"
            move['opener'] = "\"Do you wanna know who talked about this fallacy first?\""

        } else if (word.trim() === "vagueness") {
            analytics.logEvent('vagueness_popup', { test_value: 'test' });
            move['moveID'] = "VaguenessFirst"
            move['opener'] = "\"Do you wanna know who talked about this fallacy first?\""

        } else if (word.trim() === "false analogy") {
            analytics.logEvent('false_analogy_popup', { test_value: 'test' });
            move['moveID'] = "FalseAnalogyFirst"
            move['opener'] = "\"Do you wanna know who talked about this fallacy first?\""

        } else if (word.trim() === "red herring") {
            analytics.logEvent('red_herring_popup', { test_value: 'test' });
            move['moveID'] = "RedHerringFirst"
            move['opener'] = "\"Do you wanna know who talked about this fallacy first?\""
        }

        setMoves([]);
        dgep.dialogue_interaction(dialogueID, move.moveID, move, Process_moves); 
        setPastMoves([...pastMoves, {dialogueID: dialogueID, moveID: move.moveID, opener: move.opener, reply: {p: "$p"}, speaker: move.speaker, target: move.target}])
    }
   
    // Return to previous point in dialogue after fallacy 'learn more'
    const StateReturn = () => {
        pastMoves.pop();

        setMoves([]);
        dgep.dialogue_interaction(dialogueID, lastMove.moveID, lastMove, Process_moves); 
        setPastMoves([...pastMoves, {dialogueID: dialogueID, moveID: lastMove.moveID, opener: lastMove.opener, reply: {p: "$p"}, speaker: lastMove.speaker, target: lastMove.target}])
    }

    const handleEndGame = () => {
        analytics.logEvent('end_content', { test_value: 'test' });
        analytics.logEvent('TotalUserFallacies_' + totalUserFallacies.toString(), { test: 'test' });
        analytics.logEvent('TotalUserPhilosopher_' + TotalUserPhilosopherClicks.toString(), { test: 'test' });

        setMoves([])

        let move = {
            dialogueID: dialogueID,
            moveID: "EndGame",
            opener: "\"\"",
            reply: {p: "$p"},
            speaker: "Merle(Agent)",
            target: "Soraya(User)",
        }
        
        dgep.dialogue_interaction(dialogueID, move.moveID, move, Process_moves); 
        setPastMoves([...pastMoves, {dialogueID: dialogueID, moveID: move.moveID, opener: move.opener, reply: {p: "$p"}, speaker: move.speaker, target: move.target}])
    }

    const handleUserSelectExample = (game_switch_value, moveID, opener) => {

        let promise = new Promise(function(resolve, reject) {
            game_switch(game_switch_value)
            resolve("done");
        });

        promise.then(function() {
            setMoves([])

            let move = {
                dialogueID: dialogueID,
                moveID: moveID,
                opener: opener,
                reply: {p: "$p"},
                speaker: "Merle(Agent)",
                target: "Soraya(User)",
            }

            dgep.dialogue_interaction(dialogueID, move.moveID, move, Process_moves);
            setPastMoves([...pastMoves, {dialogueID: dialogueID, moveID: move.moveID, opener: move.opener, reply: {p: "$p"}, speaker: move.speaker, target: move.target}])

        }, console.log("Unable to switch game"))
    }

    const handleSetPage = () => {
        console.log(game)
        if(game == "vaccinegame") {
            setPageState(6)
        } else {
            setPageState(pageState + 1)
        }

    }

    if(pageState == 0){
        return (
            <>
                <Landing
                    vaccine={chatbot_type == null}
                    handlePageState={() => (handleSetPage())}
                />
            </>
        )
    } else if(pageState == 1) {
        return (
            <>
                <Philosophers
                    handlePageState={() => (handleSetPage())}
                    philosopher="Aristotle"
                />
            </>
        )
    } else if(pageState == 2) {
        return (
            <> 
                <Philosophers
                    handlePageState={() => (handleSetPage())}
                    philosopher="Socrates"
                />
            </>
        )
    } else if(pageState == 3) {
        return (
            <>
                <Philosophers
                    handlePageState={() => (handleSetPage())}
                    philosopher="Gorgias"
                />
            </>
        )
    } else if(pageState == 4) {
        return (
            <>
                <JarPage handlePageState={() => (handleSetPage())} />
            </>
        )
    } else if(pageState == 5) {
        return (
            <>  
                <BotChoice 
                    agentNumber={0}
                    handleClick={(bot) => handleBotSelection(bot)}
                />
            </>
        )
    } else if(pageState == 6) {
        let isUserTurn = false;
        document.body.style.backgroundColor = "#FFFFFF";
        return (
            <>

                <div id="header" style={header}>
                    <img
                        className="headerImage"
                        style={headerImage}
                        src={chatbot_type == null ? "images/FakeChatBot_Logo.png" : "images/VaccinatingNewsChatbot_Logo.png" }/>

                    <Music key={"music"} />
                </div>

                <div className="messages">
                    {
                        // Loops through and displays past moves.
                        // Past moves and current moves are treated differently
                        // See 'PastMove.js' and 'Move.js'
                        Object.entries(pastMoves).map(function(item, index){

                            setMoveTimes(item[1].opener.replace(/^"(.*)"$/, '$1'), item[1].moveID === "ShowChoices")
                            return (
                                <>
                                    <PastMove
                                        addMultipleChoice={(choice) => addMultipleChoice(choice)}
                                        vaccine_game={chatbot_type != null}
                                        readMessage={index < pastMoves.length - 2 ? true : false}
                                        move={item[1]}
                                        botChoice={botChoice}
                                        userChoice={userChoice}
                                        speakerType={item[1].speaker.includes("Agent") ? "Agent" : item[1].speaker.includes("Socrates") ? "Socrates" : item[1].speaker.includes("Gorgias") ? "Gorgias" : item[1].speaker.includes("Aristotle") ? "Aristotle" : "User"}
                                        moveText={item[1].opener.replace(/^"(.*)"$/, '$1')}
                                        speaker={item[1].speaker}
                                        stateReturn={() => StateReturn()}
                                        handleClick={(move, speaker) => handleMoveClick(move, speaker)}
                                        handlePopupLearnMore={(word) => handlePopupLearnMore(word)}
                                        game_switch={() => game_switch()}
                                        score={game_score}
                                        key={item[1].moveID}
                                    />
                                    {item[1].moveID.includes("State") ? StateReturn() : null}
                                </>
                            )
                        })
                    }
                    {activeAgent['Aristotle'] = false}
                    {activeAgent['Socrates'] = false}
                    {activeAgent['Gorgias'] = false}

                    {  // Loops through current possible moves and displays
                        Object.entries(moves).map(function(item, index){

                            return (
                                item[1].map(function(i,x) {

                                    if(item[1][x].moveID == "ChangeLevelOne"){
                                        setMoves([])
                                        setPastMoves([]);
                                        props.game_switch(1);
                                    }
                                    if(item[1][x].moveID == "ChangeLevelOnePTwo"){
                                        setMoves([])
                                        setPastMoves([]);
                                        props.game_switch(12);
                                    }
                                    if(item[1][x].moveID == "ChangeLevelOnePThree"){
                                        setMoves([])
                                        setPastMoves([]);
                                        props.game_switch(13);
                                    }
                                    if(item[1][x].moveID == "ChangeLevelOnePFour"){
                                        setMoves([])
                                        setPastMoves([]);
                                        props.game_switch(14);
                                    }
                                    if(item[1][x].moveID == "ChangeLevelOnePFive"){
                                        setMoves([])
                                        setPastMoves([]);
                                        props.game_switch(15);
                                    }
                                    if(item[1][x].moveID == "ChangeLevelOnePSix"){
                                        setMoves([])
                                        setPastMoves([]);
                                        props.game_switch(16);
                                    }
                                    if(item[1][x].moveID == "ChangeLevelTwo"){
                                        setMoves([])
                                        setPastMoves([]);
                                        props.game_switch(2);
                                    }
                                    if(item[1][x].moveID == "ChangeLevelTwoPTwo"){
                                        setMoves([])
                                        setPastMoves([]);
                                        props.game_switch(22);
                                    }
                                    if(item[1][x].moveID == "ChangeLevelTwoPThree"){
                                        setMoves([])
                                        setPastMoves([]);
                                        props.game_switch(23);
                                    }
                                    if(item[1][x].moveID == "ChangeLevelTwoPFour"){
                                        setMoves([])
                                        setPastMoves([]);
                                        props.game_switch(24);
                                    }
                                    if(item[1][x].moveID == "ChangeLevelTwoPFive"){
                                        setMoves([])
                                        setPastMoves([]);
                                        props.game_switch(25);
                                    }
                                    if(item[1][x].moveID == "ChangeLevelTwoPSix"){
                                        setMoves([])
                                        setPastMoves([]);
                                        props.game_switch(26);
                                    }
                                    if(item[1][x].moveID == "ChangeLevelThree"){
                                        setMoves([])
                                        setPastMoves([]);
                                        props.game_switch(3);
                                    }

                                    //Vaccine
                                    if(item[1][x].moveID == "ChangeVaccineIntro"){
                                        setMoves([])
                                        setPastMoves([]);
                                        props.game_switch(100);
                                    }
                                    if(item[1][x].moveID == "ChangeVaccineChoice"){
                                        setMoves([])
                                        setPastMoves([]);
                                        props.game_switch(200);
                                    }
                                    if(item[1][x].moveID == "ChangeVaccineSourcesReaction"){
                                        setMoves([])
                                        setPastMoves([]);
                                        props.game_switch(201);
                                    }
                                    if(item[1][x].moveID == "ChangeVaccineSourcesImmunisation"){
                                        setMoves([])
                                        setPastMoves([]);
                                        props.game_switch(202);
                                    }
                                    if(item[1][x].moveID == "ChangeVaccineSourcesManufacturers"){
                                        setMoves([])
                                        setPastMoves([]);
                                        props.game_switch(203);
                                    }
                                    if(item[1][x].moveID == "ChangeVaccineSourcesPoliticisation"){
                                        setMoves([])
                                        setPastMoves([]);
                                        props.game_switch(204);
                                    }
                                    if(item[1][x].moveID == "ChangeVaccineSocialReaction"){
                                        setMoves([])
                                        setPastMoves([]);
                                        props.game_switch(205);
                                    }
                                    if(item[1][x].moveID == "ChangeVaccineSocialImmunisation"){
                                        setMoves([])
                                        setPastMoves([]);
                                        props.game_switch(206);
                                    }
                                    if(item[1][x].moveID == "ChangeVaccineSocialManufacturers"){
                                        setMoves([])
                                        setPastMoves([]);
                                        props.game_switch(207);
                                    }
                                    if(item[1][x].moveID == "ChangeVaccineSocialPoliticising"){
                                        setMoves([])
                                        setPastMoves([]);
                                        props.game_switch(208);
                                    }
                                    if(item[1][x].moveID == "ChangeVaccineArticleReaction"){
                                        setMoves([])
                                        setPastMoves([]);
                                        props.game_switch(209);
                                    }
                                    if(item[1][x].moveID == "ChangeVaccineArticleImmunisation"){
                                        setMoves([])
                                        setPastMoves([]);
                                        props.game_switch(210);
                                    }
                                    if(item[1][x].moveID == "ChangeVaccineArticleManufacturers"){
                                        setMoves([])
                                        setPastMoves([]);
                                        props.game_switch(211);
                                    }
                                    if(item[1][x].moveID == "ChangeVaccineArticlePoliticising"){
                                        setMoves([])
                                        setPastMoves([]);
                                        props.game_switch(212);
                                    }
                                    if(item[1][x].moveID == "ChangeVaccineHeadlinesReaction"){
                                        setMoves([])
                                        setPastMoves([]);
                                        props.game_switch(213);
                                    }
                                    if(item[1][x].moveID == "ChangeVaccineHeadlinesImmunisation"){
                                        setMoves([])
                                        setPastMoves([]);
                                        props.game_switch(214);
                                    }
                                    if(item[1][x].moveID == "ChangeVaccineHeadlinesManufacturers"){
                                        setMoves([])
                                        setPastMoves([]);
                                        props.game_switch(215);
                                    }
                                    if(item[1][x].moveID == "ChangeVaccineHeadlinesPoliticising"){
                                        setMoves([])
                                        setPastMoves([]);
                                        props.game_switch(216);
                                    }

                                    // 'Ask' moves are hidden and only displayed when the agent is clicked in the sidebar
                                    if(typeof(item[1][x].moveID) !== 'undefined'){
                                        if(item[1][x].moveID.includes("Ask")){
                                            if(item[1][x].moveID.includes("Aristotle")){
                                                activeAgent['Aristotle'] = true;
                                                activeAgent['Aristotle_move'] = item[1][x].moveID
                                            } else if(item[1][x].moveID.includes("Gorgias")){
                                                activeAgent['Gorgias'] = true;
                                                activeAgent['Gorgias_move'] = item[1][x].moveID
                                            } else if(item[1][x].moveID.includes("Socrates")){
                                                activeAgent['Socrates'] = true;
                                                activeAgent['Socrates_move'] = item[1][x].moveID
                                            }
                                        }
                                    }

                                    return (
                                        <>
                                            <Move
                                                // sendMultipleChoice = {() => sendChoice()}
                                                key={`${item[1][x].moveID}${x}`}
                                                move={item[1][x]}
                                                userChoice={userChoice}
                                                moveText={item[1][x].opener.replace(/^"(.*)"$/, '$1')}
                                                handleClick={(move, speaker) => handleMoveClick(move, speaker)}
                                                handleBotChoice={(move, speaker, bot) => handleBotSelection(move, speaker, bot)}
                                                speaker={item[1][0].speaker}
                                                game_switch={() => game_switch()}
                                                stateReturn={() => StateReturn()}
                                                speakerType={item[1][0].speaker.includes("Agent") ? "Agent" : item[1][0].speaker.includes("Socrates") ? "Socrates" : "User"}
                                            />

                                            {/* Automatically advance to the next move if a non-user agent */}
                                            {/* {item[1][0].moveID.includes("State") ? StateReturn() : null} */}
                                            {item[1][0].speaker.includes("User") ? showTypingAnimation = false : null}
                                            {item[1][0].speaker.includes("User") ? isUserTurn = true : isUserTurn = false}
                                            {!item[1][0].speaker.includes("User") ? setMoves([]) : null}
                                            {!item[1][0].speaker.includes("User") ? dgep.dialogue_interaction(dialogueID, item[1][x].moveID, moves[item[index]][index], Process_moves) : null}
                                            {!item[1][0].speaker.includes("User") ? setPastMoves([...pastMoves, {dialogueID: dialogueID, moveID: item[1][x].moveID, opener: item[1][x].opener, reply: {p: "$p"}, speaker: item[1][x].speaker, target: item[1][x].target}]) : null}

                                            {item[1][0].speaker.includes("User") ?
                                                <>
                                                    <button
                                                        className="btnEndGame"
                                                        onClick={() => handleEndGame()}
                                                        key={"endgame"}
                                                    >End Game</button>

                                                    { chatbot_type == null ? 
                                                    null :
                                                        <div key={"NewTaskDiv"}>
                                                                <button
                                                                    className={"buttonNewTask"}
                                                                    onClick={() => {game_switch(200)}}
                                                                    key={"NewTaskButton"}
                                                                >Pick a new task</button>
                                                        </div>
                                                    }          
                                                </>
                                            : null}
                                        </>
                                    )
                                })
                            )
                        })
                    }
                </div>

                

                <div key={"DivEndGame"} >
                    {
                        (() => {
                            if (gameEnd)
                                return <></>
                            else {
                                if(showTypingAnimation) {
                                    return <p className="typingAnimation">Thinking</p>
                                } else {
                                    return <></>
                                }
                            }
                        })()
                    }
                </div>

                <div className="sideBar" key={"DivSidebar"} >
                    <AgentSidebar
                        key={"ComponentSidebar"}
                        userTurn={isUserTurn}
                        previousMove={pastMoves[pastMoves.length - 1]}
                        activeAgent={activeAgent}
                        handleAgentClick={(Agent) => handleAgentClick(Agent)}
                        botChoice={botChoice}
                        score={game_score}
                        handleUserSelectExample={(game_switch_value, moveID, opener) => handleUserSelectExample(game_switch_value, moveID, opener)}
                    />
                </div>

                {/* Div for autoscroll when a new message appears */}
                <div ref={divRef}></div>
                <Score
                    pastMoves={pastMoves}
                    handleScoreChange={(score) => {game_score = score}}
                    key={"ComponentScore"}
                />
            </>
        )
    }
}
export default App;