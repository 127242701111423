/*
    Score.js
    Compares the moves selected by the user to the correct answers and increments their score accordingly
 */

import React, {useState} from 'react';

let levelOneRightAnswers = [
    "CQuestion1yes",
   "CQuestion2yes",
   "CQuestion3no",
   "CQuestion4yes",
   "MyCQuestion1yes",
   "MyCQuestion2no",
   "MyEx6Q1Yes",
   "MyEx6Q2Yes",
   "MyEx6Q3No",
   "MyEx6Q4No",
   "MyEx6Q5Yes",
   "MyEx6Q6No",
   "MyEx6Q7No",
   "MyEx6Q9No",
   "MyEx6Q12No",
   "MyEx6Q15Yes",
   "MyEx6Q16No",
   "MyEx7Q1Yes",
   "MyEx7Q2Yes",
   "MyEx7Q3No",
   "MyEx7Q4Yes",
   "MyEx7Q5No",
   "MyEx8Q1yes",
   "MyEx8Q2yes",
   "MyEx8Q3no",
   "MyEx8Q4yes",
   "MyExQ1Yes",
   "MyExQ2Yes",
   "MyExQ3No",
   "MyExQ4Yes",
   "MyEx3Q1Yes",
   "MyEx3Q2No",
   "MyEx5Q1Yes",
   "MyEx5Q2No",
   "MyEx9Q1yes",
   "MyEx9Q2no"
]
let levelTwoRightAnswers = [
    "L2Ex1Q1Yes",
    "L2Ex1Q2Yes",
    "L2Ex1Q3No",
    "L2Ex1Q4Yes",
    "L2Ex1Q5Yes",
    "L2Ex1Q6No",
    "L2Ex1Q7No",
    "L2Ex1Q8No",
    "L2Ex1Q9No",
    "L2Ex1Q10Yes",
    "L2Ex1Q11No",
    "L2Ex2Q1Yes",
    "L2Ex2Q2Yes",
    "L2Ex2Q3No",
    "L2Ex2Q4Yes",
    "L2Ex2Q5Yes",
    "L2Ex2Q6Yes",
    "L2Ex5Q1Yes",
    "L2Ex5Q2Yes",
    "L2Ex5Q3No",
    "L2Ex5Q4No",
    "L2Ex5Q5No",
    "L2Ex5Q6No",
    "L2Ex5Q7No",
    "L2Ex5Q9No",
    "L2Ex5Q12Yes",
    "L2Ex5Q13No",
    "L2Ex4Q1Yes",
    "L2Ex4Q2Yes",
    "L2Ex4Q3No",
    "L2Ex4Q4Yes",
    "L2Ex4Q5Yes",
    "L2Ex4Q6No",
    "L2Ex4Q7Yes",
    "L2Ex4Q8Yes",
    "L2Ex3Q1Yes",
    "L2Ex3Q2Yes",
    "L2Ex3Q3No",
    "L2Ex3Q4Yes",
    "L2Ex3GorgiasQ3No",
    "L2Ex6Q1Yes",
    "L2Ex6Q2Yes",
    "L2Ex6Q3Yes",
    "L2Ex6Q5Yes",
    "L2Ex6Q6Yes",
    "L2Ex6Q7No",
    "L2Ex6Q9No",
    "L2Ex6Q19Yes",
    "L2Ex6Q120No",
    "L2Ex6Q15No",
    "L2Ex6Q18Yes",
    "L2Ex7Q1Yes",
    "L2Ex7Q2Yes",
    "L2Ex7Q3No",
    "L2Ex7Q4Yes",
    "L2Ex7Q5Yes",
    "L2Ex7Q6No",
    "L2Ex7Q7No",
    "L2Ex7Q9Yes",
    "L2Ex7Q10No",
    "L2Ex6Q12No",
]
let levelThreeRightAnswers = [
    "L3Ex1Q1Yes",
    "L3Ex1Q2Yes",
    "L3Ex1Q3No",
    "L3Ex1Q4Yes",
    "L3Ex1Q5Yes",
    "L3Ex1Q6No",
    "L3Ex1Q7Yes",
    "L3Ex1Q8Yes",
    "L3Ex1Q9No",
    "L3Ex1Q120No",
    "L3Ex1Q15No",
    "L3Ex1Q18Yes",
    "L3Ex1Q19No",
    "L3Ex1Q20Yes",
    "L3Ex1Q12No"
]

const style_score = {
    position: "fixed",
    bottom: "20px"
}

const Score = (props) => {

    let t_score = 0;
    let game_score = 0;

    const [score, setScore] = useState(0);


    props.pastMoves.forEach(function(item){

        if(levelOneRightAnswers.includes(item.moveID)){
            t_score += 1;
        }

        if(levelTwoRightAnswers.includes(item.moveID)){
            t_score += 1;
        }    

        if(levelThreeRightAnswers.includes(item.moveID)){
            t_score += 1;
        }
    })

    game_score = Math.floor(t_score / 8)

    props.handleScoreChange(game_score);
    
    return null
}

export default Score;