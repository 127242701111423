/*
    Move.js
    Displays the current moves available 
    If the current speaker is not the user, the moves are processed immediately for and subsequently become 'PastMoves'
    For the user, the moves are displayed and interaction is handled
*/

import React, {useState} from 'react'
import BotChoice from './BotChoice'
import firebase from './firebase.js'


// Styling for user 'free text' input
const textUserResponse_Style = {
    width: "50%",
    height: "80px",
    padding: "10px",
    marginRight: "5px",
    marginLeft: "50%",
    marginTop: "25px",
    display: "inline-block",
    float: "right",
    borderRadius: "5px",
    border: "1px solid #ccc",
    lineHeight: "1.5",
    color: "gray",
}

const Move = (props) => {
    let move = props.move
    let moveText = props.moveText
    let speaker = props.speaker
    let speakerType = props.speakerType
    let sendMultipleChoice = props.sendMultipleChoice

    const [userInput, setUserInput] = useState("")

    const style_menu_button = {
        position: "fixed",
        right: "150px",
        bottom: "17px",
    }

    const handleMCClick = (move, speaker) => {
        props.handleClick(move, speaker);
        sendMultipleChoice();
    }

    const handleButtonClick = (move, speaker) => {
        move.opener = userInput;

        let dateTime = new Date();
        let dateTime_string = dateTime.toString();
        let input = userInput.toString();
        let id = move.moveID.toString();

        const data_db = {
            "id": dateTime_string,
            "moveID": id,
            "text": input
        }

        if(move.moveID.toString() != "UserNameInput"){
            const itemsRef = firebase.database().ref('userinputs');
            itemsRef.push(data_db);
        }

        props.handleClick(move, speaker)
    }

    if(move.moveID == "MultipleChoiceSelectionConfirm") {
        return (
            <div className="userMessageLine">
                <button
                    className={speakerType}
                    onClick={() => handleMCClick(move, speaker)}
                >
                    {moveText}
                </button>
            </div>
        )
    }

    if(move.moveID == "ChangeLevel"){
        props.game_switch()
    }

    if(move.moveID == "BotChoiceUser"){
        return (
            <BotChoice 
                dialogueID={props.dialogueID}
                handleClick={(move, speaker, bot) => props.handleBotChoice(move, speaker, bot)}
            />
        )
    }
   
    // Hack to use hidden moves
    // Moves without an opener in DGDL are not displayed
    if(moveText == ""){
        return (
            <>
            </>
        )
    }

    if(move.moveID == "ReturnToUserChoice") {
        return (
            <div className="userMessageLine" style={style_menu_button}>
                <button
                    className={speakerType}
                    onClick={() => props.handleClick(move, speaker)}
                >
                    {moveText}
                </button>
            </div>
        )
    }

    // Correctly displays moves with hyperlinks as <a> tags
    if(move.moveID.includes("Link")) {
        return (
            <div className="divNewsLink">
                <a
                    href={moveText.replace(/^"(.*)"$/, '$1')}
                    className="newsLink"
                >
                    {moveText}
                </a>
            </div>
        )
    } 
    // If DGDL move opener contains these, a textarea for user free text response is displayed
    if(move.opener.includes("I think this because...") || move.opener.includes("Evidence...")) {
        return (
            <div className="userMessageLine">
                <textarea
                    className="textUserResponse"
                    style={textUserResponse_Style}
                    value={userInput}
                    placeholder="Enter your response"
                    onChange={e => setUserInput(e.target.value)}
                > </textarea>
                <button
                    className={speakerType}
                    onClick={() => handleButtonClick(move, speaker)}
                >
                    Enter
                </button>
            </div>
        )
    } else { // Otherwise, display normal move
        return (
            <div className="userMessageLine">
                <button
                    className={speakerType}
                    onClick={() => props.handleClick(move, speaker)}
                >
                    {moveText}
                </button> 
            </div>
        )
    }
    
}

export default Move;