/*
    firebase.js
    Setup of firebase analytics
 */

// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import firebase from "firebase";
import 'firebase/analytics'
import 'firebase/auth'

const config = {
    apiKey: "AIzaSyBd76o1YLqtYHPEyXee3Kw9sWbayNH_7Qk",
    authDomain: "fni-chatbot.firebaseapp.com",
    databaseURL: "https://fni-chatbot.firebaseio.com",
    projectId: "fni-chatbot",
    storageBucket: "fni-chatbot.appspot.com",
    messagingSenderId: "75994139889",
    appId: "1:75994139889:web:dcd5e2038909a0e3fd6d50"
};
firebase.initializeApp(config);
firebase.analytics();
firebase.auth().signInAnonymously()
  .then(() => {
    // Signed in..
  })
  .catch((error) => {
    var errorCode = error.code;
    var errorMessage = error.message;
    // ...
  });
export default firebase;