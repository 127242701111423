/*
    Philosophers.js

 */

import React, {useState} from 'react';
import Aristotle_full from './images/speakers/participants/Aristotle_full.png'
import Socrates_full from './images/speakers/participants/Socrates_full.png'
import Gorgias_full from './images/speakers/participants/Gorgias_full.png'


const Philosophers = (props) => {

    const [buttonColour, setButtonColour] = useState("#F0F0F2")

    const handleClick = () => {
        handlePageState();
    }

    let handlePageState = props.handlePageState;
    let philosopher = props.philosopher;

    const style_btnContainer = {
        textAlign: "center",
        gridArea: "2 / 1 / 3 / 3",
        marginLeft: "22%"
    }
    const style_nextButton = {
        marginTop: "10%",
        fontFamily: "Roboto",
        fontSize: "1rem",
        fontWeight: "500",
        padding: "10px",
        backgroundColor: buttonColour,
        color: "black",
        border: "1px solid black",
        boxShadow: "8px 7px 11px 3px rgba(176,176,176,0.69)",
        WebkitBoxShadow: "8px 7px 11px 3px rgba(176,176,176,0.69)",
        borderRadius: "15px",
        cursor: "pointer",
    }
    
    const style_PhilosopherDiv = {
        gridArea: "1 / 1 /2 / 2",
        textAlign: "center",
    }
    
    
    const style_Participant_Text = {
        display: "inline-block",
        gridArea: "1/ 2 / 2 / 3",
        textAlign: "justify"
    }
    
    const style_text = {
        marginTop: "30%",
        marginLeft: "5%",
        borderRadius: "12px",
        boxShadow: "8px 7px 11px 3px rgba(176,176,176,0.69)",
        fontFamily: "Roboto",
        fontSize: "1rem",
        color: "#F0F0F2",
        backgroundColor: "blue",
        padding: "12px",
        border: "1px solid black"
    }
    
    const style_color_1 = {backgroundColor: "#00447f"}
    const style_color_2 = {backgroundColor: "#6997bf"}
    const style_color_3 = {backgroundColor: "#d8e5f4", color: "black"}
    
    
    const style_Participant_Image = {
        maxHeight: "400px",
        float: "right"
    }
    
    const container = {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridTemplateRows: "1fr 1fr",
        gap: "0px 0px",
        width: "60%",
        marginLeft: "12%",
        marginTop: "5%"
    }
    
    const header = {
        width: "100%",
        boxShadow: "-1px 14px 12px -2px rgba(119,119,119,0.46)",
    }
    
    const headerImage = {
        maxWidth: "25%",
        minWidth: "250px",
        marginLeft: "10px",
        marginTop: "10px",
        marginBottom: "10px",
    }

    if(philosopher == "Aristotle"){
        return (
            <>
                <div style={header}>
                        <img 
                            style={headerImage}
                            src="images/FakeChatBot_Logo.png" />
                </div>

                <div style={container}>
                    <div  style={style_PhilosopherDiv}>
                        <img className="philosopherImage" src={Aristotle_full} alt="Aristotle" style={style_Participant_Image} />
                    </div>
                    
                    <div className="participantText" style={style_Participant_Text}>
                        <p style={{...style_text, ...style_color_1}}>Hi there, I’m Aristotle! I’m the first one to discuss the theory of fallacies. I’m here to provide you with helpful explanations and definitions! </p>
                    </div>


                    
                    <div className="nextButton" style={style_btnContainer}>
                        <button 
                        style={style_nextButton} 
                        onClick={handleClick}>NEXT</button>
                    </div>
                </div>
            </>
        )
    } else if(philosopher == "Socrates") {
        return (
            <>
            <div style={header}>
                    <img 
                        style={headerImage}
                        src="images/FakeChatBot_Logo.png" />
            </div>

                <div className="philopherContainer" style={container}>
                    <div style={style_PhilosopherDiv}>
                        <img className="philosopherImage"src={Socrates_full} alt="Socrates" style={style_Participant_Image}/>
                    </div>

                        <div className="participantText"style={style_Participant_Text}>
                            <p style={{...style_text, ...style_color_2}}>Hi there, I’m Socrates! “I know one thing that I know nothing.” But I’m here to ask questions and help you learn something! </p>
                        </div>


                        <div className="nextButton" style={style_btnContainer}>
                            <button 
                                style={style_nextButton} 
                                onClick={handleClick}>NEXT</button>
                        </div>
                </div>
            </>
        )
    } else if(philosopher == "Gorgias") {
        return (
            <>
                <div style={header}>
                        <img 
                            style={headerImage}
                            src="images/FakeChatBot_Logo.png" />
                </div>

                <div style={container}>
                    <div style={style_PhilosopherDiv}>
                            <img className="philosopherImage"src={Gorgias_full} alt="Gorgias" style={style_Participant_Image} />
                    </div>
                            <div className="participantText"style={style_Participant_Text}>
                                <p style={{...style_text, ...style_color_3}}>Hi there, I’m Gorgias! I like challenging mainstream opinions. I’m here to make the conversation more interesting! </p> 
                            </div>

                        <div className="nextButton" style={style_btnContainer}>
                            <button 
                                style={style_nextButton} 
                                onClick={handleClick}>NEXT</button>
                        </div>
                </div>
            </>
        )
    }

}

export default Philosophers;