/*
    ScoreImages.js
    Loads the score images and exports as an array
 */

import score0 from './images/other/score/score0.png'
import score1 from './images/other/score/score1.png'
import score2 from './images/other/score/score2.png'
import score3 from './images/other/score/score3.png'
import score4 from './images/other/score/score4.png'
import score5 from './images/other/score/score5.png'
import score6 from './images/other/score/score6.png'
import score7 from './images/other/score/score7.png'
import score8 from './images/other/score/score8.png'
import score9 from './images/other/score/score9.png'
import score10 from './images/other/score/score10.png'
import score11 from './images/other/score/score11.png'
import score12 from './images/other/score/score12.png'
import score13 from './images/other/score/score13.png'
import score14 from './images/other/score/score14.png'
import score15 from './images/other/score/score15.png'
import score16 from './images/other/score/score16.png'

export default [
    score0,
    score1,
    score2,
    score3,
    score4,
    score5,
    score6,
    score7,
    score8,
    score9,
    score10,
    score11,
    score12,
    score13,
    score14,
    score15,
    score16
];