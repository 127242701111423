/*
    ErrorBoundary.js
    Catches runtime errors and controls display

    TODO:
    - Add error logging to firebase
 */

import React from 'react';
import firebase from './firebase';

let analytics = firebase.analytics();


class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    // console.log("getDerivedStateFromError(error)")
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // console.log("componentDidCatch(error, errorInfo) ")
    analytics.logEvent('error', {
      error_type: error,
      error_info: errorInfo,
    });
  }

  render() {
    // if (this.state.hasError) {
    //   // You can render any custom fallback UI
    //   return <h1>Something went wrong. Click <span style={{color: "blue", cursor: "pointer"}} onClick={() => window.location.reload(true)}>here</span> to go back to the Chatbot</h1>;
    // }

    return this.props.children; 
  }
}

export default ErrorBoundary;