/*
    BotChoice.js
    Displays and handles selection of the default Agent Choice.
    The User is given a choice of agents, the selected option is then added to the sidebar
*/

import React from 'react';
import bot1 from './images/speakers/Elena.png';
import bot2 from './images/speakers/Mercutio.png';
import bot3 from './images/speakers/Elinor.png';
import bot4 from './images/speakers/Matt.png';
import bot5 from './images/speakers/Robin.png';
import bot6 from './images/speakers/Simeon.png';
import bot7 from './images/speakers/Kay.png';
import bot8 from './images/speakers/Chris.png';
import bot9 from './images/speakers/Mark.png';

// Styling for agent images
const image_style = {
    maxWidth: "100px",
    cursor: "pointer",
    marginLeft: "70px"
}

// Controls layout of bot selection grid
const style_bot1_image =    {gridArea: "1 / 1 / 2 / 2"}
let style_bot1_text =       {gridArea: "1 / 2 / 2 / 3", backgroundColor: "#F0F0F2"}
const style_bot2_image =    {gridArea: "2 / 1 / 3 / 2"}
let style_bot2_text =       {gridArea: "2 / 2 / 3 / 3", backgroundColor: "#F0F0F2"}
const style_bot3_image =    {gridArea: "3 / 1 / 4 / 2"}
const style_bot3_text =     {gridArea: "3 / 2 / 4 / 3", backgroundColor: "#F0F0F2"}
const style_bot4_image =    {gridArea: "4 / 1 / 5 / 2"}
const style_bot4_text =     {gridArea: "4 / 2 / 5 / 3", backgroundColor: "#F0F0F2"}
const style_bot5_image =    {gridArea: "1 / 3 / 2 / 4"}
const style_bot5_text =     {gridArea: "1 / 4 / 2 / 5", backgroundColor: "#F0F0F2"}
const style_bot6_image =    {gridArea: "2 / 3 / 3 / 4"}
const style_bot6_text =     {gridArea: "2 / 4 / 3 / 5", backgroundColor: "#F0F0F2"}
const style_bot7_image =    {gridArea: "3 / 3 / 4 / 4"}
const style_bot7_text =     {gridArea: "3 / 4 / 4 / 5", backgroundColor: "#F0F0F2"}
const style_bot8_image =    {gridArea: "4 / 3 / 5 / 4"}
const style_bot8_text =     {gridArea: "4 / 4 / 5 / 5", backgroundColor: "#F0F0F2"}
const style_bot9_image =    {gridArea: "5 / 1 / 6 / 2"}
const style_bot9_text =     {gridArea: "5 / 2 / 6 / 3", backgroundColor: "#F0F0F2"}

// Styling for agent images container
const BotSelectionDiv = {
    textAlign: "center",
    marginTop: "10%",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    gridTemplateRows: "1fr 1fr 1fr 1fr",
    gap: "20px 0px",
    alignItems: "center"
}

const header = {
    width: "100%",
    boxShadow: "-1px 14px 12px -2px rgba(119,119,119,0.46)",
}

const headerImage = {
    maxWidth: "25%",
    minWidth: "250px",
    marginLeft: "10px",
    marginTop: "10px",
    marginBottom: "10px",
}

const botsContainer = {
    width: "50%",
    margin: "0 auto"
}

const introText = {
    textAlign: "center",
    fontFamily: "Roboto",
    fontSize: "1rem",
    paddingTop: "50px"
}

const BotChoice = (props) => {

    let agentNumber = props.agentNumber;
    let handlePageState = props.handleClick

    const handleClick = (index) => {
        let clickedTextStyle = "style_bot" + index + "_text"
        eval(clickedTextStyle).backgroundColor = "#0065b9"

        // Slight pause before continuing to next page
        setTimeout(function () {
            handlePageState(index);
        }, 100)
    }

    const text_style = {
        color: "#305473",
        fontSize: "0.9rem",
        fontFamily: "Roboto",
        border: "1px solid black",
        boxShadow: "8px 7px 11px 3px rgba(176,176,176,0.69)",
        borderRadius: "12px",
        height: "50px",
        paddingTop: "25px",
        cursor: "pointer",
        minWidth: "max-content",
        width: "100%"
    }

    // Originally the user was given a choice for both the agent avatar and their own personal avatar
    // and checks were performed to ensure these were not the same. The user now only selects the agent
    // but these checks are still present. This does not effect the running of the program as these checks
    // are not performed (the second selection of a bot is no longer shown)
    return (
        <>
            <div style={header}>
                <img 
                    style={headerImage}
                    src="images/FakeChatBot_Logo.png" />
            </div>

            {/* If agent has not been selected */}
            {agentNumber == 0 ?
                <div  style={introText}>
                    <p className="botChoiceIntroText" >Before starting, who do you want to talk to...</p>
                </div> 
            :   <div style={introText}>
                    <p>And who would you like to be?</p>
                </div>
            }
            
            
            <div className="botsContainer" style={botsContainer}>
                <div style={BotSelectionDiv}>
                    
                    {/* One */}

                        <>
                            <img 
                                src={bot1}
                                onClick={agentNumber == 1 ? null : () => handleClick(1)}
                                style={{...style_bot1_image, ...image_style}}
                            />
                            <p 
                            onClick={agentNumber == 1 ? null : () => handleClick(1)}
                            className="botChoiceText"
                            style={{...style_bot1_text, ...text_style}}>ELENA</p>
                        </>
                    

                    {/* Two */}
                        <>
                            <img 
                                src={bot2}
                                onClick={agentNumber == 2 ? null : () => handleClick(2)}
                                style={{...style_bot2_image, ...image_style}}
                            />
                            <p 
                            onClick={agentNumber == 2 ? null : () => handleClick(2)}
                            className="botChoiceText"
                            style={{...style_bot2_text, ...text_style}}>MERCUTIO</p>      
                        </>           



                    {/* Three */}
                        <>
                            <img 
                                src={bot3}
                                onClick={agentNumber == 3 ? null : () => handleClick(3)}
                                style={{...style_bot3_image, ...image_style}}
                            />
                            <p 
                            onClick={agentNumber == 3 ? null : () => handleClick(3)}
                            className="botChoiceText"
                            style={{...style_bot3_text, ...text_style}}>ELINOR</p>      
                        </>           


                    {/* Four */}
                        <>
                            <img 
                                src={bot4}
                                onClick={agentNumber == 4 ? null : () => handleClick(4)}
                                style={{...style_bot4_image, ...image_style}}
                            />
                            <p 
                            onClick={agentNumber == 4 ? null : () => handleClick(4)}
                            className="botChoiceText"
                            style={{...style_bot4_text, ...text_style}}>MATT</p>      
                        </>           


                    {/* Five */}
                        <>
                            <img 
                                src={bot5}
                                onClick={agentNumber == 5 ? null : () => handleClick(5)}
                                style={{...style_bot5_image, ...image_style}}
                            />
                            <p 
                            onClick={agentNumber == 5 ? null : () => handleClick(5)}
                            className="botChoiceText"
                            style={{...style_bot5_text, ...text_style}}>ROBIN</p>      
                        </>           
                    


                    {/* Six */}
                        <>
                            <img 
                                src={bot6}
                                onClick={agentNumber == 6 ? null : () => handleClick(6)}
                                style={{...style_bot6_image, ...image_style}}
                            />
                            <p 
                            onClick={agentNumber == 6 ? null : () => handleClick(6)}
                            className="botChoiceText"
                            style={{...style_bot6_text, ...text_style}}>SIMEON</p>      
                        </>           



                    {/* Seven */}
                        <>
                            <img 
                                src={bot7}
                                onClick={agentNumber == 7 ? null : () => handleClick(7)}
                                style={{...style_bot7_image, ...image_style}}
                            />
                            <p 
                            onClick={agentNumber == 7 ? null : () => handleClick(7)}
                            className="botChoiceText"
                            style={{...style_bot7_text, ...text_style}}>KAY</p>      
                        </>           



                    {/* Eight */}
                    {agentNumber == 8 ?
                        null :
                        <>
                            <img 
                                src={bot8}
                                onClick={agentNumber == 8 ? null : () => handleClick(8)}
                                style={{...style_bot8_image, ...image_style}}
                            />
                            <p 
                            onClick={agentNumber == 8 ? null : () => handleClick(8)}
                            className="botChoiceText"
                            style={{...style_bot8_text, ...text_style}}>CHRIS</p>      
                        </>           
                    }


                    {/* Nine */}
                    {agentNumber == 9 ?
                        null :
                        <>
                            <img 
                                src={bot9}
                                onClick={agentNumber == 9 ? null : () => handleClick(9)}
                                style={{...style_bot9_image, ...image_style}}
                            />
                            <p 
                            onClick={agentNumber == 9 ? null : () => handleClick(9)}
                            className="botChoiceText"
                            style={{...style_bot9_text, ...text_style}}>MARK</p>      
                        </>           
                    }

                </div>
            </div>
        </>
    )
}

export default BotChoice;