/*
	Popup.js

	Display the fallacy 'learn more' popup
*/

import React from 'react'
import img_post_hoc from "./images/fallacies/post_hoc.png";
import img_cherry_picking from "./images/fallacies/cherry_picking.png";
import img_strawman from "./images/fallacies/strawman.png";
import img_false_cause from "./images/fallacies/false_cause.png";
import img_false_authority from "./images/fallacies/false_authority.png";
import img_hasty_generalization from "./images/fallacies/hasty_generalization.png";
import img_evading from "./images/fallacies/evading.png";
import img_ambiguity_vagueness from "./images/fallacies/ambiguity_vagueness.png";
import img_false_analogy from "./images/fallacies/false_analogy.png";
import img_red_herring from "./images/fallacies/red_herring.png";

let emoji_filenames = {
	'fallacies': null,
	'Post Hoc': img_post_hoc,
	'Cherry Picking': img_cherry_picking,
	'Strawman': img_strawman,
	'False Cause': img_false_cause,
	'False Authority': img_false_authority,
	'Hasty Generalisation': img_hasty_generalization,
	'Evading the Burden of Proof': img_evading,
	'Vagueness': img_ambiguity_vagueness,
	'False Analogy': img_false_analogy,
	'Red Herring': img_red_herring
}

// Styling for popup box
const popupStyle = {
	color: "black",
	backgroundColor: "white",
	padding: "20px",
	border: "1px solid black",
	borderRadius:10,
	width: "500px",
	minHeight: "250px",
	height: "auto",
	position: "fixed",
	top: "50%",
	left: "50%",
	WebkitTransform: "translate(-50%, -50%)",
	transform: "translate(-50%, -50%)",
	zIndex: "1"
};

// Popup box header styling
const popupHeaderStyle = {
	fontWeight: "bold",
	fontSize: "1em",
	textAlign: "center"
}

// Popup box div styling
const popupButtonDivStyle = {
	textAlign: "center",
	marginTop: "20px"
}

// Popup box button styling
const popupButtonStyle = {
	color: "black",
	backgroundColor: "white",
	borderRadius: "5px",
	border: "1px solid black"
}

// Popup box definition styling
const popupDefinitionStyle = {
	whiteSpace: "pre-wrap",
}

const Popup = (props) => {
	let word = props.word;
	let definition = props.definition;
	let dismissPopup = props.dismiss;
	let learnMorePopup = props.learnMorePopup;
	let learnMore = props.learnMore;
	
	return (
		<div 
			className="divPopup"
			style={popupStyle}
		>
			<h1 style={popupHeaderStyle}>{word.trim().charAt(0).toUpperCase() + word.trim().slice(1)}</h1>


			<p style={popupDefinitionStyle}>{definition}</p>
			
			<div style={popupButtonDivStyle} className="popupButtons">
				<button 
					style={popupButtonStyle}
					onClick={dismissPopup}
				>Dismiss</button>

				{learnMore ?
					<button
						style={popupButtonStyle}
						onClick={() => {learnMorePopup(word); dismissPopup();}}
					>Learn more</button>
				: null }
			</div>
		</div>
	)
}

export default Popup