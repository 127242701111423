/*
        JarPage.js
        Displays the page explaining the scoring system to the user
 */

import React, {useState} from 'react';
import jar_empty from './images/other/jar_fly.png'


const style_jar = {
    width: "140px",
    marginTop: "40px",
}

const header = {
    width: "100%",
    boxShadow: "-1px 14px 12px -2px rgba(119,119,119,0.46)",
}

const headerImage = {
    maxWidth: "25%",
    minWidth: "250px",
    marginLeft: "10px",
    marginTop: "10px",
    marginBottom: "10px",
}

const container = {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateRows: "1fr 1fr",
    gap: "0px 0px",
    width: "60%",
    marginLeft: "12%",
    marginTop: "5%"
}

const style_btnContainer = {
    textAlign: "center",
    gridArea: "2 / 1 / 3 / 3",
    marginLeft: "22%"
}

const style_PhilosopherDiv = {
    gridArea: "1 / 1 /2 / 2",
    textAlign: "right",
    marginTop: "13%"
}

const style_Participant_Text = {
    display: "inline-block",
    gridArea: "1/ 2 / 2 / 3",
    textAlign: "justify"
}

const style_color_1 = {backgroundColor: "#f0f0f2"}

const style_text = {
    marginTop: "30%",
    marginLeft: "5%",
    borderRadius: "12px",
    boxShadow: "8px 7px 11px 3px rgba(176,176,176,0.69)",
    fontFamily: "Roboto",
    fontSize: "1rem",
    color: "black",
    backgroundColor: "#d6d6d6",
    padding: "12px",
    border: "1px solid black"
}

const style_nextButton = {
    marginTop: "10%",
    fontFamily: "Roboto",
    fontSize: "1rem",
    fontWeight: "500",
    padding: "10px",
    backgroundColor: "#f0f0f2",
    color: "black",
    border: "1px solid black",
    WebkitBoxShadow: "8px 7px 11px 3px rgba(176,176,176,0.69)",
    boxShadow: "8px 7px 11px 3px rgba(176,176,176,0.69)",
    borderRadius: "15px",
    cursor: "pointer",
}

const JarPage = (props) => {

    let handlePageState = props.handlePageState;

    const handleClick = () => {
        handlePageState();
    }

    return (
        <>
            <div style={header}>
                    <img 
                        style={headerImage}
                        src="images/FakeChatBot_Logo.png" />
            </div>

            <div className="divJarContainer" style={container}>
                <div style={style_PhilosopherDiv}>
                    <img 
                        src={jar_empty} 
                        style={style_jar}
                        className="jarImage"
                    />
                </div>
                
                <div className="participantText" style={style_Participant_Text}>
                    <p style={{...style_text, ...style_color_1}}>For every 8 correct answers, you'll receive one gadfly in your jar as a reward for becoming an expert in recognising fallacious news. Why the gadfly? Plato describes Socrates as a gadfly who stings people with his questions to keep them on track in the pursuit of virtue. </p>
                </div>

                <div className="nextButton" style={style_btnContainer}>
                    <button 
                    style={style_nextButton} 
                    onClick={handleClick}>NEXT</button>
                </div>
            </div>
        </>
    )
}

export default JarPage;