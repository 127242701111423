/*
    AgentSidebar.js
    Controls the agent images to the side of the main chatbot.
    Displays the current user score
    Displays url to elicit user feedback

    Two styles are used: 
    - style_AgentImage: the default agent image styling
    - style_Active_AgentImage: activated when a 'help' move is available for the corresponding agent 
*/

import React, {useEffect} from 'react'

// Imports for philosopher images
import socrates_circle from './images/speakers/participants/Socrates_circle.png'
import gorgias_circle from './images/speakers/participants/Gorgias_circle.png'
import aristotle_circle from './images/speakers/participants/Aristotle_circle.png'
import question_mark from './images/other/questionMark.png'



import scoreImages from './ScoreImages'


// Styling for the Agent side bar
// default styling for sidebar
const style_AgentSidebar = {
    margin: "0 auto",
    top: "30%",
    position: "relative",

}

const style_AgentText = {
    fontFamily: "Roboto",
    fontSize: "0.8rem",
    margin: "-10px",
    color: "#474747",
    paddingTop: "10px"
}

// default styling for sidebar images
const style_AgentImage = {
    width: "17%",
    height: "17%",
    minWidth: "60px",
    minHeight: "60px",
}

// styling for 'active' image (when click for help is available)
const style_Active_AgentImage = {
    minWidth: "60px",
    minHeight: "60px",
    width: "17%",
    height: "17%",
    marginTop: "10px",
    border: "3px solid red",
    borderRadius: "50%",
    padding: "2px",
    cursor: "pointer",
}

// style for '?' when image is active
const style_question_mark = {
    width: "67px",
    height: "25px",
    marginLeft: "70px",
    position: "relative",
    top: "-35px"
}

const style_example_select = {	
    fontFamily: "Roboto",	
    width: "40%",	
    borderRadius: "5px",	
    marginTop: "25px",	
    marginBottom: "20px",	
    fontSize: "0.8rem",	
    backgroundColor: "#f0f0f2",	
    fontWeight: "500",	
}

const linkFeedbackStyle = {
    color: "#1491ff"
}

const divFeedbackStyle = {
    position: "absolute",
    width: "100%",
    bottom: "0.5%"
}


const AgentSidebar = (props) => {

    let score = props.score;
    let activeAgent = props.activeAgent;
    let handleAgentClick = props.handleAgentClick;


    useEffect(() => {
        let display_score = document.getElementById("displayScore")
        display_score.id = "displayScore_active"

        setTimeout(() => {
            display_score.id = "displayScore"
        }, 2000)
    }, [score])


    return (
        <>
        <div className="sidebarContent" style={style_AgentSidebar}>
            <div>
                <img
                    id="displayScore"
                    src={scoreImages[score]}
                    style={style_AgentImage}
                />
            </div>

            <br/>

            {/* Images for 3 philosophers. Active styling used in a 'help' move for that speaker is available */}
            <img 
                className={activeAgent['Socrates']? "activeAgent" : null}
                src={socrates_circle} 
                style={activeAgent['Socrates']? style_Active_AgentImage : style_AgentImage}
                onClick={activeAgent['Socrates']? () => handleAgentClick(activeAgent['Socrates_move']) : null}
            />
            <p style={style_AgentText}>Socrates</p>
            {activeAgent['Socrates'] ?
                <img
                    className="philosopherQuestionMark"
                    src={question_mark}
                    style={style_question_mark}
                />
                : null }

            <br/>
            <img 
                className={activeAgent['Gorgias']? "activeAgent" : null}
                src={gorgias_circle} 
                style={activeAgent['Gorgias'] ? style_Active_AgentImage : style_AgentImage}
                onClick={activeAgent['Gorgias']? () => handleAgentClick(activeAgent['Gorgias_move']) : null}
            />
            <p style={style_AgentText}>Gorgias</p>
            {activeAgent['Gorgias'] ? 
                <img
                    className="philosopherQuestionMark"
                    src={question_mark}
                    style={style_question_mark}
                />
            :   null}

            <br/>
            <img 
                className={activeAgent['Aristotle']? "activeAgent" : null}
                src={aristotle_circle} 
                style={activeAgent['Aristotle'] ? style_Active_AgentImage : style_AgentImage}
                onClick={activeAgent['Aristotle']? () => handleAgentClick(activeAgent['Aristotle_move']) : null}
            />
            <p style={style_AgentText}>Aristotle</p>
            {activeAgent['Aristotle'] ? 
                <img
                    className="philosopherQuestionMark"
                    src={question_mark}
                    style={style_question_mark}
                />
            :   null}

        </div>

            {/* Feedback URL */}
            <div style={divFeedbackStyle}>
                <a 
                href={'https://liverpoolcommsmedia.fra1.qualtrics.com/jfe/form/SV_cumhqbTOi8rJTud'}
                target="_blank"
                style={linkFeedbackStyle}>Give us feedback!</a>
            </div>
        </>
    )
}

export default AgentSidebar;
