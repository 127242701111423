/*
    Landing.js
    The initial landing page displayed when the website is loaded
 */

import React, {useState} from 'react';

let descriptionText = "";
const Landing = (props) => {

    let handlePageState = props.handlePageState;
    let vaccine = props.vaccine;

    if(vaccine) {
        descriptionText = "Welcome to the Fake News Immunity Chatbot. This Chatbot will help you learn how to spot fallacies (arguments that appear to be valid but are not) in Fake and Semi-Fake news stories. By answering questions from Aristotle, Gorgias, and Socrates, you will learn how to approach a piece of news with a critical eye and how to be on the lookout for misleading or deceptive stories. Why Socrates, Aristotle, and Gorgias? Because in combination these thinkers are the first who systematically worked on searching the truth through questions and answers (Socratic/dialectic method), who tried to define rhetorical strategies (Aristotle) and undermined mainstream opinions on what is true and what is fallacious (Gorgias). During the game, the participants on the left-hand side can provide you with suggestions and advice. A participant that is highlighted in red can be clicked on to receive a prompt relevant to the question you are being asked. Click the ‘Begin’ button to meet the Philosophers."
    } else {
        descriptionText = "Welcome to the Vaccinating News Chatbot. This Chatbot will help you learn how to avoid fallacies (arguments that appear to be valid but are not) when shaping news stories. Three Greek philosophers will help you learn how to write  a piece of news with a critical eye both on official and social media and how to be on the lookout for picking misleading headlines and (multimodal) sources. Why Socrates, Aristotle, and Gorgias? Because in combination these thinkers are the first who systematically worked on searching the truth through questions and answers (Socratic/dialectic method), who tried to define rhetorical strategies (Aristotle) and undermined mainstream opinions on what is true and what is fallacious (Gorgias). During the game, the participants on the left-hand side can provide you with suggestions and advice. A participant that is highlighted in red can be clicked on to receive a prompt relevant to the question you are being asked. Click the ‘Begin’ button to get started."
    }

    const [buttonColour, setButtonColour] = useState("#F0F0F2")

    const handleClick = () => {
        setButtonColour("#0065b9");

        setTimeout(function () {
            handlePageState();
        }, 100)
    }

    const style_btnContainer = {
        textAlign: "center",
        marginBottom: "70px"
    }
    const style_nextButton = {
        marginTop: "20px",
        fontFamily: "Roboto",
        fontSize: "0.9rem",
        fontWeight: "500",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingRight: "15px",
        paddingLeft: "15px",
        backgroundColor: buttonColour,
        color: "black",
        border: "1px solid black",
        WebkitBoxShadow: "8px 7px 11px 3px rgba(176,176,176,0.69)", 
        boxShadow: "8px 7px 11px 3px rgba(176,176,176,0.69)",
        MozBoxShadow: "8px 7px 11px 3px rgba(176,176,176,0.69)",
        borderRadius: "12px",
        cursor: "pointer"
    }
    
    const introText = {
        fontFamily: "Roboto",
        fontSize: "0.9rem",
        padding: "10px",
        lineHeight: "1.5"
    } 


    return (
        <>
            <div>
                <div className="header">
                    <img 
                        src={vaccine ? "images/FakeChatBot_Logo.png" : "images/VaccinatingNewsChatbot_Logo.png"}
                        className="pageLogo"
                    />
                </div>

                <div className="div_introText">
                    <p style={introText}>{descriptionText}</p>
                </div>
            </div>


            <div style={style_btnContainer}>
                <button 
                    onClick={handleClick}
                    style={style_nextButton}
                >BEGIN</button>
            </div>


            <div className="musicCreditsDiv">
                <p className="musicCredits">Music kindly provided by <a target="_blank" href="https://open.spotify.com/artist/3c9xybRYUsqIRjWTeKqABb">Hopey Parish</a></p>
            </div>
        </>
    )
}

export default Landing;